import { IStyles } from './types';

import ContrastLightTheme from './themes/contrastLight';
import ContrastDarkTheme from './themes/contrastDark';
import DarkTheme from './themes/dark';
import LightTheme from './themes/light';
import Header from './components/header';
import Keyboard from './components/keyboard';
import Grid from './components/grid';
import Page from './components/page';
import StatisticsPage from './components/statisticsPage';
import Modal from './components/modal';
import TextInput from './components/textInput';
import { ETheme } from '@contexts/types';

export default (theme: ETheme): IStyles => {
  const Styles: any = {};

  if (theme == ETheme.contrastLight) {
    Styles.colors = ContrastLightTheme.colors;
  } else if (theme == ETheme.contrastDark) {
    Styles.colors = ContrastDarkTheme.colors;
  } else if (theme == ETheme.light) {
    Styles.colors = LightTheme.colors;
  } else if (theme == ETheme.dark) {
    Styles.colors = DarkTheme.colors;
  } else {
    Styles.colors = DarkTheme.colors;
  }

  Styles.components = {
    page: Page(Styles.colors),
    header: Header(Styles.colors),
    keyboard: Keyboard(Styles.colors),
    grid: Grid(Styles.colors),
    statisticsPage: StatisticsPage(Styles.colors),
    modal: Modal(Styles.colors),
    textInput: TextInput(Styles.colors)
  }

  return Styles;
}