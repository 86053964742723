import Dark from './dark';

const output: any = {};
const overwrite = {
  common: {
    primary: '#f5793a',
    primaryHover: '#e06f36',
    secondary: '#939598',
    secondaryHover: '#828487'
  },
  gameplay: {
    textPartial: 'white',
    bgTileCorrect: '#f5793a',
    bgTilePartial: '#85c0f9',
    bgBtnCorrect: '#f5793a',
    bgBtnPartial: '#85c0f9',
  }
};


for(const [k, v] of Object.entries(JSON.parse(JSON.stringify(Dark)).colors)) {
  const newV: any = new Object(v);
  //@ts-ignore
  for (const [k1, v1] of Object.entries(overwrite[k])) {
    newV[k1] = v1;
  }
  output[k] = newV;
}

export default { colors: output };
