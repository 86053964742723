import { createContext, useContext, useEffect, useState } from 'react'
import {
  onAuthStateChanged,
} from 'firebase/auth'
import FirebaseApi from '@helpers/Firebase';
import { getDoc, doc, query, where, collection, orderBy, limit } from "firebase/firestore";

const Firebase = new FirebaseApi();

const AuthContext = createContext<any>({})
export const useAuth = () => useContext(AuthContext)

const getUserRoles = async (uid: string) => {
  try {
    const docRef = doc(Firebase.firestore, "users", uid);
    const userDoc = await getDoc(docRef);
    const user: any = userDoc.data();
    return user.roles;
  } catch(e) {
    console.error("Error getting user roles: " + e);
  }
  return [];
}

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [user, setUser] = useState<any>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(Firebase.auth, async (user) => {
      if (user) {
        const roles = await getUserRoles(user.uid);
        setUser({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          roles: roles || ['user']
        })
      } else {
        setUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  const login = () => {
    Firebase.authenticate();
  };

  const logout = () => {
    Firebase.logout();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {loading ? null : children}
    </AuthContext.Provider>
  )
}