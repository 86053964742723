
export default (colors: any) => ({
  container: {
    height: 65,
    maxWidth: 900,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 5,
    borderBottom: 'solid 1px rgba(128,128,128,0.5)'
  },
  subContainer: {
    bottom: 5,
    position: 'absolute',
    width: '100%',
  },
  content: {
    height: 40,
    color: colors.common.textPrimary,
    textAlign: 'center',
    fontSize: 34,
    letterSpacing: 1,
    fontWeight: 'bold',
    fontFamily: "yesevaone",
  },
  contentLeft: {
    height: 40,
    bottom: 5,
    position: 'absolute',
    color: colors.common.textPrimary
  },
  contentRight: {
    height: 40,
    bottom: 5,
    position: 'absolute',
    zIndex: 100,
    color: colors.common.textPrimary
  },
})