import React, { useEffect, useRef } from 'react';
import { Animated, Modal, View } from 'react-native';
import { CustomText } from '../CustomText';

export const Notification = ({ data }: any) => {
  const styling: any = { position: 'absolute', width: '100%', zIndex: 500, top: 100 };
  if (!data.show) {
    styling.display = 'none';
  } 
  return (
      <View style={styling}>
        <View style={{ marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'gray', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
          <CustomText style={{ color: 'white', textAlign: 'center', marginTop: 'auto', marginBottom: 'auto', fontSize: 16 }}>
            {data.text}
          </CustomText>
        </View>
      </View>
  )
}
