import React, { useState, useEffect, useContext } from 'react';
import { View } from 'react-native';
import { CustomText, CustomTextRegular } from '../../components/CustomText';
import { ThemeContext } from '@contexts/themeContext';

const calculateTimeRemainingUntilMidnight = () => {
  const currentTime = new Date();
  const midnight = new Date(currentTime)
  midnight.setDate(midnight.getDate() + 1)
  midnight.setHours(0,0,0,0);
  const dif = midnight.getTime() - currentTime.getTime();
  return Math.abs(dif / 1000);
}

export const Countdown = () => {
  const { styles } = useContext(ThemeContext);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(calculateTimeRemainingUntilMidnight());
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <View style={{ width: '100%' }}>
      <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginLeft: 'auto', marginRight: 'auto', fontSize: 14 }}>NĀKAMAIS VĀRDULIS</CustomTextRegular>
      <CustomText style={{ color: styles.colors.common.textPrimary, marginLeft: 'auto', marginRight: 'auto', fontSize: 26 }}>{`${Math.floor(seconds / (60 * 60))}h ${Math.floor((seconds / 60) % 60)}m ${Math.floor(seconds) % 60}s`}</CustomText>
    </View>
  )
}

