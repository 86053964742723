import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as SplashScreen from 'expo-splash-screen';
import Constants from "expo-constants";
import { Helmet } from "react-helmet";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from '@react-navigation/native-stack';
// import CookieConsent from "react-cookie-consent";

import Styles from '@root/src/styles';

import ChangeLogPage from '@pages/ChangelogPage';
import Contact from '@pages/UserContact';
import ContactMessages from '@pages/UserContactMessages';
import CustomSelect from '@pages/CustomSelect';
import GameLog from '@pages/GameLog';
import Instruction from '@pages/Instruction';
import Menu from '@pages/Menu';
import News from '@pages/News';
import Profile from '@pages/Profile';
import Settings from '@pages/Settings';
import StatisticsPage from '@pages/StatisticsPage';
import UnderConstruction from '@pages/UnderConstruction';
import { Game4, Game5, Game6, Game7, Game8 } from '@pages/game';

import { ChangelogModal } from '@components/ChangelogModal';

import { useFonts } from '@hooks/useFonts';
import { useConfig } from '@hooks/useConfig';

import NotificationContextProvider from '@contexts/notificationContext';
import { AuthContextProvider } from '@contexts/authContext';
import { ETheme } from '@contexts/types';
import { ThemeContext } from '@contexts/themeContext';

import FirebaseApi from '@helpers/Firebase';
import { getDataFunctionsObject } from "@helpers/data";

const Stack = createNativeStackNavigator();

interface EThemeObject {
  name: ETheme;
  style: any;
}

const Firebase = new FirebaseApi();
Firebase.askForPermissioToReceiveNotifications();

SplashScreen.preventAutoHideAsync();

export default function App() {

  const [DF] = useState(getDataFunctionsObject());
  const [modalVisible, setModalVisible] = useState(false);
  const [theme, setTheme] = useState<EThemeObject>({
    name: ETheme.dark,
    style: Styles(ETheme.dark)
  });
  const [appIsReady, setAppIsReady] = useState(false);
  const {config} = useConfig();

  const updateTheme = (val: ETheme) => {
    DF.setTheme(val.toString());
    setTheme({ name: val, style: Styles(val) });
  }

  const LoadFonts = async () => {
    await useFonts();
  };

  // @ts-ignore
  useEffect(() => {
    try {
      DF.updateToNewFormat();
      
      DF.getTutorialAccepted().then((isTutorialAccept) => {
        if (!isTutorialAccept) {
          //! setPage(EPage.instruction)
        }
      });
        
      DF.getTheme().then((res) => {
        setTheme({
          name: res,
          style: Styles(res)
        });
      });

      LoadFonts();
      
      const isUpdateAccepted = async () => {
        let acceptVersion = await DF.getUpdateAccept();
        if (acceptVersion) {
          const version = Constants?.manifest?.version;
          console.info(`App version: ${version}`);
          return version == acceptVersion;
        }
        return false;
      }
      
      isUpdateAccepted().then((res: any) => {
        setModalVisible(!res);
      });
    } catch (e) {
      console.warn(e);
    } finally {
      setAppIsReady(true);
    }
  }, [])

  // const onLayoutRootView = useCallback(async () => {
  //   if (appIsReady) {
  //     // This tells the splash screen to hide immediately! If we call this after
  //     // `setAppIsReady`, then we may see a blank screen while the app is
  //     // loading its initial state and rendering its first pixels. So instead,
  //     // we hide the splash screen once we know the root view has already
  //     // performed layout.
  //     await SplashScreen.hideAsync();
  //   }
  // }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8545060200703953"
          crossOrigin="anonymous"
        ></script>
        {/* <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@nytimesbits" />
        <meta name="twitter:creator" content="@nickbilton" />
        <meta property="og:url" content="http://bits.blogs.nytimes.com/2011/12/08/a-twitter-for-my-sister/" />
        <meta property="og:title" content="A Twitter for My Sister" />
        <meta property="og:description" content="In the early days, Twitter grew so quickly that it was almost impossible to add new features because engineers spent their time trying to keep the rocket ship from stalling." />
        <meta property="og:image" content="http://graphics8.nytimes.com/images/2011/12/08/technology/bits-newtwitter/bits-newtwitter-tmagArticle.jpg" /> */}
      </Helmet>
      <AuthContextProvider>
        {/* <DataFunctionContext.Provider> */}
          <ThemeContext.Provider value={{styles: theme.style, theme: theme.name, setTheme: (value: ETheme) => updateTheme(value)}}>
            <ChangelogModal modalVisible={modalVisible} setModalVisible={setModalVisible}/>
            <NotificationContextProvider>
              {/* <View
                style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}
                onLayout={onLayoutRootView}
              > */}
              {
                  !config.isAppEnabled 
                  ? <UnderConstruction/>
                  : <NavigationContainer>
                  <Stack.Navigator
                    screenOptions={{
                      headerShown: false,
                    }}
                  >
                    <Stack.Screen
                      name="news"
                      component={News}
                      options={{
                        title: "Vārdulis"
                      }}
                    />
                    <Stack.Screen
                      name="menu"
                      component={Menu}
                      options={{
                        title: "Vārdulis"
                      }}
                    />
                    <Stack.Screen
                      name="changes"
                      component={ChangeLogPage}
                      options={{
                        title: "Vārdulis - Izmaiņu vēsture"
                      }}
                    />
                    <Stack.Screen
                      name="contact"
                      component={Contact}
                      options={{
                        title: "Vārdulis - Ziņojums"
                      }}
                    />
                    <Stack.Screen
                      name="contactmessages"
                      component={ContactMessages}
                      options={{
                        title: "Vārdulis - Ziņojumi"
                      }}
                    />
                    <Stack.Screen
                      name="gameLog"
                      component={GameLog}
                      options={{
                        title: "Vārdulis"
                      }}
                    />
                    <Stack.Screen
                      name="game4"
                      component={Game4}
                      options={{
                        title: "Vārdulis - 4 burtu spēle"
                      }}
                    />
                    <Stack.Screen
                      name="game5"
                      component={Game5}
                      options={{
                        title: "Vārdulis - 5 burtu spēle"
                      }}
                    />
                    <Stack.Screen
                      name="game6"
                      component={Game6}
                      options={{
                        title: "Vārdulis - 6 burtu spēle"
                      }}
                    />
                    <Stack.Screen
                      name="game7"
                      component={Game7}
                      options={{
                        title: "Vārdulis - 7 burtu spēle"
                      }}
                    />
                    <Stack.Screen
                      name="game8"
                      component={Game8}
                      options={{
                        title: "Vārdulis - 8 burtu spēle"
                      }}
                    />
                    <Stack.Screen
                      name="customSelect"
                      component={CustomSelect}
                      options={{
                        title: "Vārdulis"
                      }}
                    />
                    <Stack.Screen
                      name="statistics"
                      component={StatisticsPage}
                      options={{
                        title: "Vārdulis - Statistika"
                      }}
                    />
                    <Stack.Screen
                      name="instruction"
                      component={Instruction}
                      options={{
                        title: "Vārdulis - Instrukcijas"
                      }}
                    />
                    <Stack.Screen
                      name="settings"
                      component={Settings}
                      options={{
                        title: "Vārdulis - Iestatījumi"
                      }}
                    />
                    <Stack.Screen
                      name="profile"
                      component={Profile}
                      options={{
                        title: "Vārdulis - Profils"
                      }}
                    />
                  </Stack.Navigator>
                </NavigationContainer>
              }

              {/* </View> */}
            </NotificationContextProvider>
          </ThemeContext.Provider>
        </AuthContextProvider>
        {/* </DataFunctionContext.Provider> */}
      {/* !!! REMOVE DEBUG !!! */}
      {/* <CookieConsent debug={true} location="top" buttonText="Pieņemt un turpināt">
        <View style={{marginLeft: 'auto', marginRight: 'auto'}}>
          <Text style={{color: "white"}}>
            Lai uzlabotu lietotāja pieredzi, šī vietne izmanto sīkdatnes (angļu val. "cookies"). Turpinot Jūs piekrītat, ka Jūsu ierīcē tiek uzkrātas un izmantotas sīkdatnes.
          </Text>
        </View>
      </CookieConsent> */}
    </>
  );
}
