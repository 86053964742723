import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth, signInWithPopup, signInWithRedirect, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, collection, doc, setDoc, connectFirestoreEmulator } from "firebase/firestore";
import { Platform } from 'react-native';
import * as FirebaseCore from 'expo-firebase-core';
import Constants from 'expo-constants';
import { getRemoteConfig, getValue, getString, fetchAndActivate } from "firebase/remote-config";

// import { httpsCallable } from "firebase/functions";


// const firebaseConfig = {
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID
// };

const VAPID_KEY = "BFjbGqrT7IZ3SzQGt8w3zx73SaEC097WNSllpsLleFUGRBFODP497sydfJ2iYN_WFnLlxtBesocpo1G05WGs6DE";

export default class FirebaseAPI {
  app: any;
  messaging: any;
  token: any;
  auth: any;
  firestore: any;
  functions: any;
  remoteConfig: any;
  AvailabilityConfig: {
    "isAppEnabled"?: boolean,
    "isAuthEnabled"?: boolean,
    "isContactEnabled"?: boolean,
    "isStatisticsEnabled"?: boolean,
    "isGame4Enabled"?: boolean,
    "isGame5Enabled"?: boolean,
    "isGame6Enabled"?: boolean,
    "isGame7Enabled"?: boolean,
    "isGame8Enabled"?: boolean,
    "isNewsEnabled"?: boolean,
  } = {
    "isAppEnabled": true,
    "isAuthEnabled": true,
    "isContactEnabled": true,
    "isStatisticsEnabled": true,
    "isGame4Enabled": true,
    "isGame5Enabled": true,
    "isGame6Enabled": true,
    "isGame7Enabled": true,
    "isGame8Enabled": true,
    "isNewsEnabled": true,
  };

  constructor() {
    // Initialize Firebase
    this.app = initializeApp({
      ...FirebaseCore.DEFAULT_WEB_APP_OPTIONS,
    });
    this.firestore = getFirestore();
    this.functions = getFunctions(this.app);
    this.auth = getAuth();
    this.remoteConfig = getRemoteConfig(this.app);
    this.remoteConfig.defaultConfig = {
      AvailabilityConfig: this.AvailabilityConfig
    };

    if (Constants?.expoConfig?.extra?.env === "development") {
      connectFunctionsEmulator(this.functions, window.location.hostname, 5001);
      connectFirestoreEmulator(this.firestore, window.location.hostname, 8080);
      connectAuthEmulator(this.auth, `http://${window.location.hostname}:9099`, { disableWarnings: true });
      this.remoteConfig.settings.minimumFetchIntervalMillis = 5000;
    } else {
      this.remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 15;

      fetchAndActivate(this.remoteConfig)
      .then((res) => {
        // console.log(res);
        this.AvailabilityConfig = JSON.parse(getString(this.remoteConfig, 'AvailabilityConfig'));
      })
      .catch((err) => {
        console.log(err);
      });
      
      // Initialize Firebase Cloud Messaging and get a reference to the service
      if (Platform.OS === 'web') {
        this.messaging = getMessaging(this.app);
        // this.messaging.getToken({vapidKey: VAPID_KEY});
        onMessage(this.messaging, (payload) => {
          console.log('Message received. ', payload);
          // ...
        });
      }
    }
  }

  public askForPermissioToReceiveNotifications = async () => {
    if (!this.messaging) return;
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const token = getToken(this.messaging, { vapidKey: VAPID_KEY }).then((currentToken) => {
      if (currentToken) {
        this.token = currentToken;
        // console.log("Messaging token: \n" + currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  }

  public authenticate = async () => {
    const provider = new GoogleAuthProvider();

    // Check if authentication property is set to enabled
    const isAuthEnabled = this.AvailabilityConfig.isAuthEnabled;
    if (!isAuthEnabled) return;

    signInWithRedirect(this.auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) {
        throw new Error("No credential");
      }
      const token = credential.accessToken;
      // The signed-in user info.
      // const user = result.user;
      // ...
    }).catch((error) => {
      console.error(error);
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
  }

  public logout = async () => {
    this.auth.signOut();
  };

  public testFirestore = async () => {
    const testRef = collection(this.firestore, "test");

    await setDoc(doc(testRef, "test"), {
      name: "test",
      age: 1
    });
  }

  // public startGame = async (gameType: string) => {
  //   const f_startGame = httpsCallable(this.functions, 'startGame');
  //   const res: any = await f_startGame({gameType, date: new Date()});

  //   // Get the game ID from res
  //   const gameId = res.data.gameId;
  //   const answer = res.data.answer;
  // }

  // public submitWord = async (type: number, word: string) => {
  //   const submitWord = httpsCallable(this.functions, 'submitWord');
  //   const date = new Date();

  //   console.log({
  //     type,
  //     date: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
  //     word
  //   });

  //   const res: any = await submitWord({
  //     type,
  //     date: `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
  //     word
  //   })

  //   console.log(res);

  //   // const gameInputRef = collection(this.firestore, "game");

  //   // await setDoc(doc(gameInputRef, gameInput.id), {
  //   //   ...gameInput,
  //   //   userId: this.auth.currentUser?.uid,
  //   //   updatedAt: new Date()
  //   // });
  // }
}

