import React, { useState, useContext, useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons'; 
import * as Analytics from 'expo-firebase-analytics';

import { getDataFunctionsObject } from "../helpers/data";
import { Header, Button as CustomButton } from '@components';
import { getDate } from '@helpers/date';
import { ThemeContext } from '@contexts/themeContext';
import { useConfig } from '@hooks/useConfig';

export default function CustomSelect({ navigation }: any) {
  const { config } = useConfig();

  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;
  const { styles } = useContext(ThemeContext);
  const [ gamesPlayed, setGamesPlayed ] = useState({
    4: false,
    5: false,
    6: false,
    7: false,
    8: false
  });

  const select = (number: number) => {
    try {
      Analytics.logEvent('CustomSelect', {
        gameType: number
      });
    } catch (e) {
      console.error("Firebase error");
    }
    navigation.navigate(`game${number}`);
  }

  useEffect(() => {
    const getPlayedGames = async () => {
      const game4 = DF.getTodaysGame(4);
      const game5 = DF.getTodaysGame(5);
      const game6 = DF.getTodaysGame(6);
      const game7 = DF.getTodaysGame(7);
      const game8 = DF.getTodaysGame(8);

      const todaysDate = getDate(new Date());

      const res = await Promise.all([game4, game5, game6, game7, game8]);

      return {
        4: res[0] && res[0].date == todaysDate && res[0].gameState?.isEnd == true,
        5: res[1] && res[1].date == todaysDate && res[1].gameState?.isEnd == true,
        6: res[2] && res[2].date == todaysDate && res[2].gameState?.isEnd == true,
        7: res[3] && res[3].date == todaysDate && res[3].gameState?.isEnd == true,
        8: res[4] && res[4].date == todaysDate && res[4].gameState?.isEnd == true
      }
    }

    getPlayedGames().then((res) => {
      setGamesPlayed(res)
    })
  }, []);

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ maxWidth: 400, width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="4 burtu Vārdulis"
            disabled={!config.isGame4Enabled}
            emoji={<MaterialCommunityIcons name="numeric-4-box-outline" size={30} color={styles.colors.common.btnText} />}
            color={gamesPlayed['4'] ? styles.colors.common.secondary : styles.colors.common.primary}
            hoverColor={gamesPlayed['4'] ? styles.colors.common.secondaryHover : styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => select(4)}
          />
        </View>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>

          <CustomButton
            text="5 burtu Vārdulis"
            disabled={!config.isGame5Enabled}
            emoji={<MaterialCommunityIcons name="numeric-5-box-outline" size={30} color={styles.colors.common.btnText} />}
            color={gamesPlayed['5'] ? styles.colors.common.secondary : styles.colors.common.primary}
            hoverColor={gamesPlayed['5'] ? styles.colors.common.secondaryHover : styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => select(5)}
          />
        </View>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="6 burtu Vārdulis"
            disabled={!config.isGame6Enabled}
            emoji={<MaterialCommunityIcons name="numeric-6-box-outline" size={30} color={styles.colors.common.btnText} />}
            color={gamesPlayed['6'] ? styles.colors.common.secondary : styles.colors.common.primary}
            hoverColor={gamesPlayed['6'] ? styles.colors.common.secondaryHover : styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => select(6)}
          />
        </View>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="7 burtu Vārdulis"
            disabled={!config.isGame7Enabled}
            emoji={<MaterialCommunityIcons name="numeric-7-box-outline" size={30} color={styles.colors.common.btnText} />}
            color={gamesPlayed['7'] ? styles.colors.common.secondary : styles.colors.common.primary}
            hoverColor={gamesPlayed['7'] ? styles.colors.common.secondaryHover : styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => select(7)}
          />
        </View>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="8 burtu Vārdulis"
            disabled={!config.isGame8Enabled}
            emoji={<MaterialCommunityIcons name="numeric-8-box-outline" size={30} color={styles.colors.common.btnText} />}
            color={gamesPlayed['8'] ? styles.colors.common.secondary : styles.colors.common.primary}
            hoverColor={gamesPlayed['8'] ? styles.colors.common.secondaryHover : styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => select(8)}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#121213"
  },
});
