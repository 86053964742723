
export default (colors: any) => ({
  keyButton: {
    backgroundColor: colors.gameplay.bgBtnUnused,
    height: 50,
    margin: 1,
    borderRadius: 2,
    border: `1 ${colors.gameplay.bgBtnUnusedDark} inset`,
  },
  key: {
    container: {
      flexDirection: "column",
      height: "100%"
    },
    key_unused: {
      backgroundColor: colors.gameplay.bgBtnUnused,
    },
    key_correct: {
      backgroundColor: colors.gameplay.bgBtnCorrect,
      boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    },
    key_partial: {
      backgroundColor: colors.gameplay.bgBtnPartial,
      boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    },
    key_used: {
      backgroundColor: colors.gameplay.bgBtnWrong,
    },
    key_text_unused: {
      color: colors.gameplay.textUnused,
    },
    key_text_correct: {
      color: colors.gameplay.textCorrect,
    },
    key_text_partial: {
      color: colors.gameplay.textPartial,
    },
    key_text_used: {
      color: colors.gameplay.textWrong,
    },
    keyText: {
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: "400",
    },
    keyTextBold: {
      marginTop: 'auto',
      marginBottom: 'auto',
      color: 'white',
      textAlign: 'center',
      fontSize: 20,
      fontWeight: 'bold',
      textShadowColor: 'rgba(0,0,0,0.2)',
      textShadowRadius: 2
    },
    row: {
      width: '100%',
      flexDirection: "row",
      justifyContent: "center",
      maxWidth: 700,
      margin: "auto"
    }
  }
})