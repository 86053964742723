import React, { useContext } from 'react';
import { View } from 'react-native';
import { CustomText } from '../../components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { Button } from '../../components/Button';
import { WordGrid } from '../WordGrid';
import { Ionicons } from '@expo/vector-icons';

const HistoricGame = ({ gameData, guessData, select, isSelected }: any) => {
  const { styles } = useContext(ThemeContext);

  return <>
    <Button
      text={ `${gameData.date}  ${gameData.isSynced ? ' (s)' : ''}${gameData.isWin ? ' (w)' : ' (l)'}` }
      emoji={
        <Ionicons
          selectable={false}
          name="today-outline"
          size={30}
          color={styles.colors.common.textPrimary}
        />
      }
      color={isSelected ? styles.colors.common.primary : styles.colors.common.secondary}
      hoverColor={isSelected ? styles.colors.common.primaryHover : styles.colors.common.secondaryHover}
      textColor={styles.colors.common.btnText}
      onClick={() => select()}
    />
    {/* <CustomText onClick={() => select()} style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 20, marginTop: 20, marginBottom: 10}}>
      { gameData.date }
    </CustomText> */}
    {
      isSelected && 
      <View style={{marginTop: 20, marginBottom: 20}}>
        <WordGrid guesses={guessData} letterSizeCustom={40}/>
        <CustomText style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 20, marginTop: 20, marginBottom: 10 }}>Pareizais vārds bija "{gameData.gameState.correctWord.toUpperCase()}"</CustomText>{}
      </View>
    }
  </>;
}

export default HistoricGame;