import React, { useContext, useState, useRef, useEffect } from 'react';
import { Button, StyleSheet, View, TextInput, Dimensions } from 'react-native';
import { CustomText, Header } from '@components';
import { CustomTextRegular } from '@components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { Ionicons } from '@expo/vector-icons'; 
import { getDataFunctionsObject } from "@helpers/data";
import { useNotification } from '@hooks/useNotification';
import Firebase from "@helpers/Firebase";
import { getDateTime } from '@helpers/date';
import { useAuth } from '@contexts/authContext';
const screenHeight = Dimensions.get('window').height;

const firebase = new Firebase();

const useReport = (reportId?: string) => {
  const [DF,] = useState(() => getDataFunctionsObject());
  const [report, setReport] = useState<any>();

  useEffect(() => {
    if (!reportId || typeof reportId !== 'string') return;
    const getReport = async () => {
      DF.getReport(reportId).then((report) => {
        setReport(report);
      });
    }
    getReport();
  }, [reportId]);

  return {report, setReport};
}

const Message = ({message, isResponse=true}: any) => {
  const { styles } = useContext(ThemeContext);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 5 }}>
    {
      isResponse
      ? <View style={{ flex: 1, alignItems: 'flex-start'}}>
          <CustomTextRegular style={{ opacity: 0.4, color: styles.colors.common.textPrimary }}>{getDateTime(message.created)}</CustomTextRegular>
          <View style={{ padding: 10, paddingLeft: 15, paddingRight: 15,borderRadius: 20, borderTopLeftRadius: 0, backgroundColor: styles.colors.common.background2, marginRight: 10 }}>
            <CustomTextRegular style={{ fontSize: 14, color: styles.colors.common.textPrimary }}>{message.message}</CustomTextRegular>
          </View>
        </View>
      : <View style={{ flex: 1, alignItems: 'flex-end'}}>
          <CustomTextRegular style={{ opacity: 0.4, color: styles.colors.common.textPrimary }}>{getDateTime(message.created)}</CustomTextRegular>
          <View style={{ padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: 20, borderTopRightRadius: 0, backgroundColor: styles.colors.common.primary }}>
            <CustomTextRegular style={{ fontSize: 14, color: styles.colors.common.btnText }}>{message.message}</CustomTextRegular>
          </View>
        </View>
    }
    </View>
  )
}

const MessageInput = ({reportId, navigation}: { reportId?: string, navigation?: any }) => {
  const { show } = useNotification();
  const { styles } = useContext(ThemeContext);
  const [values, setValues] = useState({
    body: ''
  });
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;

  const send = async () => {
    if (reportId) {
      const res = await DF.updateReport(reportId, values.body);
      show(res.message);
      navigation.navigate('news');
      navigation.navigate('contact', { reportId: reportId });
    } else {
      const res = await DF.submitReport(values.body);
      show(res.message);
      navigation.navigate('news');
      navigation.navigate('contact', { reportId: res.id })
    }
  }

  return (

    <View style={{ marginTop: 20, justifyContent: 'center' }}>
      <View style={{alignItems: 'stretch', flexDirection: 'row'}}>
        <TextInput
          onChangeText={text => setValues(current => ({...current, body: text}))}
          value={values.body}
          style={{ flex: 1, padding: 10, borderWidth: 2, borderColor: styles.colors.common.background2, borderRadius: 5, color: styles.colors.common.textPrimary }}
          multiline
          editable
          placeholder="Ziņojuma saturs"
        />
        <View style={{ width: 50, backgroundColor: styles.colors.common.primary, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <Ionicons
            selectable={false}
            name="send-outline"
            size={20}
            style={{ padding: 7, textAlign: 'center', marginBottom: 'auto', marginTop: 'auto', color: styles.colors.common.btnText }}
            onPress={() => send()}
          />
        </View>
      </View>
    </View>
  )
}

const ExistingMessage = ({reportId, navigation}: {reportId: string, navigation: any}) => {
  const { styles } = useContext(ThemeContext);
  const { report } = useReport(reportId);
  const userId = firebase.auth.currentUser.uid;
  if (!report) return <></>;

  return (
    <View style={{ flex: 1, padding: 20, overflow: 'scroll', borderColor: styles.colors.common.background2, borderWidth: 2, marginTop: 40, display: 'flex', flexDirection: 'column-reverse' }}>
    {
      report && report.messages.reverse().map((message: any, index: number) => {
        return <Message key={index} message={message} isResponse={userId !== message.uid}/>
      })
    }
    </View>
  );
}

const AdminInfo = ({reportId}: {reportId: string}) => {
  const { report } = useReport(reportId);
  const { styles } = useContext(ThemeContext);
  const [DF,] = useState(() => getDataFunctionsObject());
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    if (report) {
      const getUserData = async () => {
        setUserData(await DF.getUserData(report.uid));
      }
      getUserData();
    }
  }, [report])
  
  if (!userData) {
    return <></>
  }

  return (
    <View>
      <CustomText style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>UID</CustomText>
      <CustomTextRegular selectable={true} style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>{userData?.data?.uid}</CustomTextRegular>
      <CustomText style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>Email</CustomText>
      <CustomTextRegular selectable={true} style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>{userData?.data?.email}</CustomTextRegular>
      <CustomText style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>Display Name</CustomText>
      <CustomTextRegular selectable={true} style={{ fontSize: 16, color: styles.colors.common.textPrimary }}>{userData?.data?.displayName}</CustomTextRegular>
    </View>
  );
}

export default function ContactReport({navigation, route}: {navigation: any, route: any}) {
  const { user } = useAuth();
  const { styles } = useContext(ThemeContext);
  const findUserReport = async () => {
    const report = await getDataFunctionsObject().findUserReport();

    if (report.ok) {
      navigation.navigate('contact', { reportId: report.id })
    }
  }

  useEffect(() => {
    if (!(route?.params?.reportId)) findUserReport();
  }, []);

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ height: screenHeight - 88, width: '100%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto' }}>
        <View style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 40, alignContent: "center" }}>
          {
            user.roles.includes('admin') && route?.params?.reportId
            ? <AdminInfo reportId={route.params.reportId}/>
            : <CustomTextRegular style={{ fontSize: 16, fontWeight: 'bold', color: styles.colors.common.textPrimary }}>Ziņojums "Vārdulis" administratoram</CustomTextRegular>
          }
        </View>
        {
          route?.params?.reportId ? <ExistingMessage reportId={route.params.reportId} navigation={navigation}/> : <></>
        }
        <MessageInput reportId={route?.params?.reportId} navigation={navigation}/>
      </View>
    </View>
  );
}
