import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { CustomText, Header } from "@components";
import { CustomTextRegular } from "@components/CustomText";
import { ThemeContext } from "@contexts/themeContext";
import { useAuth } from "@contexts/authContext";
import { Button as CustomButton } from "@components/Button";
import { useDF } from "@hooks/useDF";
import { useConfig } from "@hooks/useConfig";
import { getDate } from "@helpers/date";

type StatsType = {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  x?: number;
};

interface DailyNewsProps {
  data: {
    title: string;
    text: string;
    stats: any;
    created: string;
    updated: string;
  };
}

const roundPercentage = (ipt: Array<number>, precision?: number) => {
  if (!precision) {
    precision = 0;
  }
  if (!Array.isArray(ipt)) {
    throw new Error("percentRound input should be an Array");
  }
  const iptPercents = ipt.slice();
  const length = ipt.length;
  const out = new Array(length);

  let total = 0;
  for (let i = length - 1; i >= 0; i--) {
    if (typeof iptPercents[i] === "string") {
      //@ts-ignore
      iptPercents[i] = Number.parseFloat(iptPercents[i]);
    }
    total += iptPercents[i] * 1;
  }

  if (isNaN(total)) {
    throw new Error("percentRound invalid input");
  }

  if (total === 0) {
    out.fill(0);
  } else {
    const powPrecision = Math.pow(10, precision);
    const pow100 = 100 * powPrecision;
    let check100 = 0;
    for (let i = length - 1; i >= 0; i--) {
      iptPercents[i] = (100 * iptPercents[i]) / total;
      check100 += out[i] = Math.round(iptPercents[i] * powPrecision);
    }

    if (check100 !== pow100) {
      const totalDiff = check100 - pow100;
      const roundGrain = 1;
      let grainCount = Math.abs(totalDiff);
      const diffs = new Array(length);

      for (let i = 0; i < length; i++) {
        diffs[i] = Math.abs(out[i] - iptPercents[i] * powPrecision);
      }

      while (grainCount > 0) {
        let idx = 0;
        let maxDiff = diffs[0];
        for (let i = 1; i < length; i++) {
          if (maxDiff < diffs[i]) {
            // avoid negative result
            if (check100 > pow100 && out[i] - roundGrain < 0) {
              continue;
            }
            idx = i;
            maxDiff = diffs[i];
          }
        }
        if (check100 > pow100) {
          out[idx] -= roundGrain;
        } else {
          out[idx] += roundGrain;
        }
        diffs[idx] -= roundGrain;
        grainCount--;
      }
    }

    if (powPrecision > 1) {
      for (let i = 0; i < length; i++) {
        out[i] = out[i] / powPrecision;
      }
    }
  }

  return out;
};

const Bar = ({
  percentage = 0,
  label,
  color,
}: {
  percentage: number;
  label: string;
  color: string;
}) => {
  const { styles } = useContext(ThemeContext);
  return (
    <View
      style={{ width: "100%", height: 20, marginTop: 5, flexDirection: "row" }}
    >
      <CustomText
        style={{ color: styles.colors.common.textPrimary, width: 15 }}
      >
        {label}
      </CustomText>
      <View
        style={{ flex: 1, backgroundColor: styles.colors.common.background1 }}
      >
        <View
          style={{
            minWidth: 30,
            width: `${Math.round(percentage)}%`,
            height: "100%",
            backgroundColor: color,
          }}
        >
          <CustomTextRegular
            style={{
              position: "absolute",
              color: styles.colors.common.btnText,
              right: 5,
            }}
          >
            {`${Math.round(percentage)}%`}
          </CustomTextRegular>
        </View>
      </View>
    </View>
  );
};

const DailyNews = ({ data }: DailyNewsProps) => {
  const { styles } = useContext(ThemeContext);
  const [stats, setStats] = useState<StatsType>();

  useEffect(() => {
    if (!data.stats) return;

    const statsData = data.stats;
    let percentages = [0, 0, 0, 0, 0, 0, 0];
    try {
      percentages =
        data?.stats &&
        roundPercentage([
          statsData["1"] || 0,
          statsData["2"] || 0,
          statsData["3"] || 0,
          statsData["4"] || 0,
          statsData["5"] || 0,
          statsData["6"] || 0,
        ]);
    } catch (e: any) {
      console.error(e.message);
    }
    if (!percentages || percentages.length < 6) return;
    setStats({
      1: percentages[0],
      2: percentages[1],
      3: percentages[2],
      4: percentages[3],
      5: percentages[4],
      6: percentages[5],
    });
  }, []);

  if (!stats) return <></>;
  return (
    <>
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[1]}
        label="1"
      />
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[2]}
        label="2"
      />
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[3]}
        label="3"
      />
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[4]}
        label="4"
      />
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[5]}
        label="5"
      />
      <Bar
        color={styles.colors.common.primary}
        percentage={stats[6]}
        label="6"
      />
      {/* <Bar
        color={styles.colors.common.secondary}
        percentage={stats["x"]}
        label="x"
      /> */}
    </>
  );
};

const useNews = () => {
  const [news, setNews] = useState([]);
  const DF = useDF();

  useEffect(() => {
    const getNews = async () => {
      try {
        setNews(await DF.getNews());
      } catch (e) {
        console.log(e);
      }
    };
    getNews();
  }, []);

  return news;
};

export default function News({ navigation }: { navigation: any }) {
  const news = useNews();
  const { user, login } = useAuth();
  const { config } = useConfig();
  const { styles } = useContext(ThemeContext);

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation} />
      <View
        style={{
          maxWidth: 600,
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 40,
        }}
      >
        <View style={{ marginBottom: 20 }}>
          {user && user.roles.includes("admin") && (
            <View
              style={{
                marginBottom: 40,
                marginLeft: "auto",
                marginRight: "auto",
                width: "90%",
                paddingLeft: "auto",
                paddingRight: "auto",
              }}
            >
              <CustomButton
                text="Lietotāju ziņojumi"
                disabled={!config.isAuthEnabled}
                emoji={
                  <Ionicons
                    selectable={false}
                    name="alert-circle-outline"
                    size={30}
                    color={styles.colors.common.textPrimary}
                  />
                }
                color={styles.colors.common.primary}
                hoverColor={styles.colors.common.primaryHover}
                textColor={styles.colors.common.btnText}
                onClick={() => navigation.navigate("contactmessages")}
              />
            </View>
          )}
          {!user && (
            <View
              style={{
                marginBottom: 40,
                marginLeft: "auto",
                marginRight: "auto",
                width: "90%",
                paddingLeft: "auto",
                paddingRight: "auto",
              }}
            >
              <CustomButton
                text="Pieslēgties"
                disabled={!config.isAuthEnabled}
                emoji={
                  <Ionicons
                    selectable={false}
                    name="log-in-outline"
                    size={30}
                    color={styles.colors.common.textPrimary}
                  />
                }
                color={styles.colors.common.primary}
                hoverColor={styles.colors.common.primaryHover}
                textColor={styles.colors.common.btnText}
                onClick={() => login()}
              />
            </View>
          )}
          <View
            style={{
              marginBottom: 10,
              marginLeft: "auto",
              marginRight: "auto",
              width: "90%",
              paddingLeft: "auto",
              paddingRight: "auto",
            }}
          >
            <CustomButton
              text="Šīs dienas vārdulis"
              disabled={!config.isGame5Enabled}
              emoji={
                <Ionicons
                  selectable={false}
                  name="today-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                />
              }
              color={styles.colors.common.primary}
              hoverColor={styles.colors.common.primaryHover}
              textColor={styles.colors.common.btnText}
              onClick={() => navigation.navigate("game5")}
            />
          </View>
          <View
            style={{
              marginBottom: 10,
              marginLeft: "auto",
              marginRight: "auto",
              width: "90%",
              paddingLeft: "auto",
              paddingRight: "auto",
            }}
          >
            <CustomButton
              text="Pielāgotais vārdulis"
              disabled={
                !config.isGame4Enabled &&
                !config.isGame5Enabled &&
                !config.isGame6Enabled &&
                !config.isGame7Enabled &&
                !config.isGame8Enabled
              }
              emoji={
                <Ionicons
                  selectable={false}
                  name="calendar-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                />
              }
              color={styles.colors.common.primary}
              hoverColor={styles.colors.common.primaryHover}
              textColor={styles.colors.common.btnText}
              onClick={() => navigation.navigate("customSelect")}
            />
          </View>
        </View>

        {/* <View style={{ borderWidth: 2, borderColor: styles.colors.common.background3 }}> */}
        {config.isNewsEnabled && (
          <>
            <CustomText
              style={{
                color: styles.colors.common.textPrimary,
                fontSize: 20,
                marginBottom: 5,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              JAUNUMI
            </CustomText>
            {config.isNewsEnabled &&
              news?.map((item: any, key: number) => (
                <View
                  key={key}
                  style={{
                    borderWidth: 2,
                    marginTop: 20,
                    borderColor: styles.colors.common.background3,
                  }}
                >
                  <View
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "100%",
                      paddingLeft: "auto",
                      paddingRight: "auto",
                      borderColor: styles.colors.common.background3,
                      borderBottomWidth: 2,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: styles.colors.common.background3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 10,
                        marginBottom: 10,
                        flexDirection: "row",
                      }}
                    >
                      <View>
                        <CustomText
                          style={{
                            color: styles.colors.common.textPrimary,
                            marginBottom: 10,
                            fontSize: 16,
                          }}
                        >
                          {item.title}
                        </CustomText>
                      </View>
                      <View
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        <CustomText
                          style={{
                            color: styles.colors.common.textSecondary,
                            marginBottom: 10,
                            fontSize: 12,
                            marginTop: 3,
                          }}
                        >
                          {getDate(new Date(item.created))}
                        </CustomText>
                      </View>
                    </View>
                    <View
                      style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 10,
                      }}
                    >
                      {item?.text
                        ?.split("\\n")
                        .map((text: string, index: number) => {
                          return (
                            <CustomTextRegular
                              key={index}
                              style={{
                                color: styles.colors.common.textSecondary,
                                textAlign: "left",
                                fontSize: 15,
                                marginBottom: 8,
                              }}
                            >
                              {text}
                            </CustomTextRegular>
                          );
                        })}
                      {item.type === "DAILY" && <DailyNews data={item} />}
                    </View>
                  </View>
                </View>
              ))}
          </>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#121213",
  },
});
