import LocalFunctions from "./local";
import FirestoreFunctions from "./firestore";
import { getAuth } from "firebase/auth";

// const TEMP_PARM_IS_DB = true;

// import {
//   clearAllData,
//   clearTodaysGame,
//   getGameLogs,
//   getGameScore,
//   getLastReloadTime,
//   getTheme,
//   getTodaysGame,
//   getTutorialAccepted,
//   getUpdateAccept,
//   setUpdateAccept,
//   setGameScore,
//   setLastReloadTime,
//   setTheme,
//   setTodaysGame,
//   setTutorialAccepted,
//   updateToNewFormat,
// } from './local';

// import {
//   clearAllData as firestoreClearAllData,
//   clearTodaysGame as firestoreClearTodaysGame,
//   getGameLogs as firestoreGetGameLogs,
//   getGameScore as firestoreGetGameScore,
//   getLastReloadTime as firestoreGetLastReloadTime,
//   getTheme as firestoreGetTheme,
//   getTodaysGame as firestoreGetTodaysGame,
//   getTutorialAccepted as firestoreGetTutorialAccepted,
//   getUpdateAccept as firestoreGetUpdateAccept,
//   setUpdateAccept as firestoreSetUpdateAccept,
//   setGameScore as firestoreUpdateGameScore,
//   setLastReloadTime as firestoreUpdateLastReloadTime,
//   setTheme as firestoreSetTheme,
//   setTodaysGame as firestoreUpdateTodaysGame,
//   setTutorialAccepted as firestoreSetTutorialAccepted,
// } from './firestore';

// enum EFunctionKey {
//   "clearAllData",
//   "clearTodaysGame",
//   "getGameLogs",
//   "getGameScore",
//   "getLastReloadTime",
//   "getTheme",
//   "getTodaysGame",
//   "getTutorialAccepted",
//   "getUpdateAccept",
//   "setGameScore",
//   "setLastReloadTime",
//   "setTheme",
//   "setTodaysGame", // TODO - should have a check and return a value if input word is correct before updating data
//   "setTutorialAccepted",
//   "setUpdateAccept",
//   "updateToNewFormat"
// }

// const LOCAL_FUNCTION_MAP = new Map<EFunctionKey, Function>([
//   [EFunctionKey.clearAllData, clearAllData],
//   [EFunctionKey.clearTodaysGame, clearTodaysGame],
//   [EFunctionKey.getGameLogs, getGameLogs],
//   [EFunctionKey.getGameScore, getGameScore],
//   [EFunctionKey.getLastReloadTime, getLastReloadTime],
//   [EFunctionKey.getTheme, getTheme],
//   [EFunctionKey.getTodaysGame, getTodaysGame],
//   [EFunctionKey.getTutorialAccepted, getTutorialAccepted],
//   [EFunctionKey.getUpdateAccept, getUpdateAccept],
//   [EFunctionKey.setGameScore, setGameScore],
//   [EFunctionKey.setLastReloadTime, setLastReloadTime],
//   [EFunctionKey.setTheme, setTheme],
//   [EFunctionKey.setTodaysGame, setTodaysGame],
//   [EFunctionKey.setTutorialAccepted, setTutorialAccepted],
//   [EFunctionKey.setUpdateAccept, setUpdateAccept],
//   [EFunctionKey.updateToNewFormat, updateToNewFormat]
// ]);

// const FIRESTORE_FUNCTION_MAP = new Map<EFunctionKey, Function>([
//   [EFunctionKey.clearAllData, () => {}],
//   [EFunctionKey.clearTodaysGame, () => {}], // Data on server will not be cleared, used for client side
//   [EFunctionKey.getGameLogs, firestoreGetGameLogs],
//   [EFunctionKey.getGameScore, firestoreGetGameScore],
//   [EFunctionKey.getLastReloadTime, firestoreGetLastReloadTime],
//   [EFunctionKey.getTheme, firestoreGetTheme],
//   [EFunctionKey.getTodaysGame, firestoreGetTodaysGame],
//   [EFunctionKey.getTutorialAccepted, firestoreGetTutorialAccepted],
//   [EFunctionKey.getUpdateAccept, firestoreGetUpdateAccept],
//   [EFunctionKey.setGameScore, firestoreUpdateGameScore],
//   [EFunctionKey.setLastReloadTime, firestoreUpdateLastReloadTime],
//   [EFunctionKey.setTheme, firestoreSetTheme],
//   [EFunctionKey.setTodaysGame, firestoreUpdateTodaysGame],
//   [EFunctionKey.setTutorialAccepted, firestoreSetTutorialAccepted],
//   [EFunctionKey.setUpdateAccept, firestoreSetUpdateAccept],
//   [EFunctionKey.updateToNewFormat, () => {}]
// ]);

// TODO - If user is logged in these functions will be called once and saved on client side. When creating data it will be created both in client side and on server side to save server resources.
// TODO - Remove "Clear all data" button if user is logged in.


export const getDataFunctionsObject = () => {
  const auth = getAuth();
  // Check if is logged in or has known user ID
  if (auth.currentUser) {
    // console.log("USING FIRESTORE");
    return new FirestoreFunctions();
  } else {
    // console.log("USING LOCAL");
    return new LocalFunctions();
  }
}
