import { useRef } from "react";
import { NotificationContext } from "../contexts/notificationContext";
import { getDataFunctionsObject } from "../helpers/data";
import { getAuth } from "firebase/auth";


export const useDF = () => {
  const DF = useRef(getDataFunctionsObject());

  return DF.current;
}
