export const getDate = (date: any) => {
  const yyyy = date.getFullYear();
  let mm = '' + (date.getMonth() + 1); // Months start at 0!
  let dd = '' + date.getDate();

  if (parseInt(dd) < 10) dd = '0' + dd;
  if (parseInt(mm) < 10) mm = '0' + mm;

  return `${dd}/${mm}/${yyyy}`;
}

export const getDateTime = (date: any) => {
  const newDate = new Date(date);
  const yyyy = newDate.getFullYear();
  let mm = '' + (newDate.getMonth() + 1); // Months start at 0!
  let dd = '' + newDate.getDate();
  let hh = '' + newDate.getHours();
  let min = '' + newDate.getMinutes();
  let sec = '' + newDate.getSeconds();

  if (parseInt(dd) < 10) dd = '0' + dd;
  if (parseInt(mm) < 10) mm = '0' + mm;
  if (parseInt(hh) < 10) hh = '0' + hh;
  if (parseInt(min) < 10) min = '0' + min;
  if (parseInt(sec) < 10) sec = '0' + sec;

  return `${dd}/${mm}/${yyyy} ${hh}:${min}:${sec}`;
}