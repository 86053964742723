import React, { useContext, useRef } from 'react';
import { Alert, View, Platform } from 'react-native';
import { Header } from '@components';
import { getDataFunctionsObject } from "@helpers/data";
import { ThemeContext } from '@contexts/themeContext';
import { Ionicons } from '@expo/vector-icons'; 
import { Button as CustomButton } from '@components/Button';
import { useAuth } from '@contexts/authContext';
import { useNotification } from '@hooks/useNotification';
import { CustomText, CustomTextRegular } from '@components/CustomText';

export default function Profile({navigation}: {navigation: any}) {
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;
  const { user, logout } = useAuth()
  const { styles } = useContext(ThemeContext);
  const { show } = useNotification();

  const openConfirmation = () => {
    if (Platform.OS === 'web' && window) {
      if (confirm("Vai esi pārliecināts, ka vēlies sinhronizēt lokāli saglabātos spēļu datus?\n> Spēles datus var sinhronizēt tikai ar vienu profilu\n> Sinhronizētie dati tiks sasaistīti ar pašreizējo profilu\n> Sinhronizētos datus nevarēs izdzēst no profila")) {
        const syncData = async () => {
          const res = await DF.syncLocalData();
          if (!res.ok) return;
          show(res.message);
        }
        syncData();
      }
    } else {
      Alert.alert(
        "Spēles datu sinhronizēšana",
        "Vai esi pārliecināts, ka vēlies sinhronizēt lokāli saglabātos spēļu datus?\n> Spēles datus var sinhronizēt tikai ar vienu profilu\n> Sinhronizētie dati tiks sasaistīti ar pašreizējo profilu\n> Sinhronizētos datus nevarēs izdzēst no profila",
        [
          {
            text: "Sinhronizēt",
            onPress: async () => {
              const res = await DF.syncLocalData();
              if (!res.ok) return;
              show(res.message);
            }
          },
          {
            text: "Atcelt",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel"
          }
        ]
      );
    }
  }

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ maxWidth: 400, width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
        <CustomTextRegular style={{ color: styles.colors.common.textSecondary, fontSize: 20, textAlign: 'left' }}>Vārds:</CustomTextRegular>
        <CustomText style={{ color: styles.colors.common.textPrimary, fontSize: 20, textAlign: 'left' }}>{user.displayName}</CustomText>
        <CustomTextRegular style={{ color: styles.colors.common.textSecondary, fontSize: 20, marginTop: 10, textAlign: 'left' }}>E-pasts:</CustomTextRegular>
        <CustomText style={{ color: styles.colors.common.textPrimary, fontSize: 20, marginBottom: 25, textAlign: 'left' }}>{user.email}</CustomText>
        <View style={{ marginBottom: 10, flex: 1 }}>
          <CustomButton
            text="Mana Statistika"
            emoji={
              <Ionicons
                selectable={false}
                name="stats-chart-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.secondary}
            hoverColor={styles.colors.common.secondaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('statistics')}
          />
        </View>
        <View style={{ marginBottom: 10, flex: 1 }}>
          <CustomButton
            text="Sinhronizēt lokālos datus"
            emoji={
              <Ionicons
                selectable={false}
                name="sync-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.secondary}
            hoverColor={styles.colors.common.secondaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => openConfirmation()}
          />
        </View>
        <View style={{ marginBottom: 10, flex: 1 }}>
          <CustomButton
            text="Izrakstīties"
            emoji={
              <Ionicons
                selectable={false}
                name="exit-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.warning}
            hoverColor={styles.colors.common.warningHover}
            textColor={styles.colors.common.btnText}
            onClick={() => {
              logout()
              navigation.navigate('news')
            }}
          />
        </View>
      </View>
    </View>
  );
}