
export default {
  colors: {
    common: {
      primary: '#6aaa64',
      primaryHover: '#538d4e',
      secondary: '#939598',
      secondaryHover: '#7e8082',
      warning: '#aa2222',
      warningHover: '#7d1a1a',
      textPrimary: 'black',
      textSecondary: '#222222',
      background: 'white',
      background2: '#cccccc',
      background3: '#eeeeee',
      blurColor: 'rgba(255,255,255,0.5)',
      btnText: 'white'
    },
    gameplay: {
      textCorrect: 'white',
      textPartial: 'white',
      textWrong: '#eeeeee',
      textUnused: '#444444',
      textTileCorrect: 'white',
      textTilePartial: 'white',
      textTileWrong: 'black',
      textTileEmpty: 'black',
      bgTileCorrect: '#6aaa64',
      bgTilePartial: '#c9b458',
      bgTileWrong: '#86888a',
      bgBtnCorrect: '#6aaa64',
      bgBtnPartial: '#c9b458',
      bgBtnWrong: '#818384',
      bgBtnUnused: '#d3d6da',
    }
  }
}