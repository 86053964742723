import React, { useRef } from 'react';
import { StyleSheet, Pressable, View } from 'react-native';
import { CustomText } from '@components/CustomText';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

export const Button = ({ emoji, disabled, text, onClick, color, hoverColor = color, textColor, style, textStyle }: any) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocus = useFocus(ref);
  const isActive = useActive(ref);
  
  const buttonColor = disabled || (isHovered || isFocus || isActive) ? hoverColor : color;

  return (
    <Pressable onPress={() => { !disabled && onClick() }} ref={ref}>
      <View style={[{ opacity: disabled ? 0.3 : 1, flexDirection: 'row', width: "100%"}, style]}>

        <View style={[{ width: 40, paddingLeft: 8, paddingTop: 1, borderRadius: 4, backgroundColor: buttonColor, borderBottomRightRadius: 0, borderTopRightRadius: 0 }]}>
          {React.cloneElement(emoji, { color: textColor })}
        </View>
        <View style={[{ padding: 8, paddingLeft: 10, borderRadius: 4, backgroundColor: buttonColor, flexGrow: 1, borderBottomLeftRadius: 0, borderTopLeftRadius: 0}]}>
          {/* <CustomText style={styles.surface}>&nbsp;&nbsp;&nbsp;</CustomText> */}
          <CustomText style={[styles.surface, { color: textColor, fontSize: 16, textTransform: 'uppercase', justifyContent: 'center', alignItems: 'center'}, textStyle]}>{text}</CustomText>
        </View>
      </View>
        {
          disabled &&
          <View style={{width: '100%', height: '100%', position: "absolute", opacity: 1}}>
            <CustomText style={[
              styles.surface, { 
                color: textColor,
                fontSize: 14,
                textTransform: 'uppercase',
                margin: 'auto',
                marginRight: 20,
                justifyContent: 'center',
                alignItems: 'center',
                textShadowRadius: 3,
                textShadowColor: 'black',
                textShadowOffset: { width: 0, height: 0 },
              },
              textStyle
            ]}>Pašlaik nav pieejams</CustomText>
          </View>
        }
    </Pressable>
  ) 
}

const styles = StyleSheet.create({
  surface: {
    alignItems: 'center',
    elevation: 2,
    justifyContent: 'center',
  },
});
