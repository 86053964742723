import React, { useEffect, useState, useContext, useRef } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Header } from '@components';
import { CustomText } from '@components/CustomText';
import { Statistics } from '@components/Statistics';
import { History } from '@components/History';
import { Ionicons } from '@expo/vector-icons';
import { ThemeContext } from '@contexts/themeContext';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

export default ({ navigation }: any) => {
  const { styles } = useContext(ThemeContext);
  const [wordLength, setWordLength] = useState(5);
  const statisticsPage = styles.components.statisticsPage;

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={[statisticsPage.menuContainer]}>
        <View style={[statisticsPage.selectContainer]}>
          {
            [4,5,6,7,8].map((val) => {
              return <PressableButton 
                key={val}
                val={val}
                wordLength={wordLength}
                statisticsPage={statisticsPage}
                setWordLength={() => setWordLength(val)}
              />
            })
          }
        </View>
        <View style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, backgroundColor: styles.colors.common.background }}>
          <Statistics wordLength={wordLength}/>
        </View>
        <View style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, backgroundColor: styles.colors.common.background }}>
          <History wordLength={wordLength}/>
        </View>
      </View>
    </View>
  )
}

const PressableButton = ({val, wordLength, statisticsPage, setWordLength}: any) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  // const isFocus = useFocus(ref);
  // const isActive = useActive(ref);

  const styles = [];
  if (wordLength == val) {
    styles.push(statisticsPage.select);
    styles.push(statisticsPage.selectHighlight);
  } else {
    styles.push(statisticsPage.select);
  }
  // styles.push({
  //   opacity: isHovered ? 0.85 : 1
  // });

  return (
    <Pressable key={val} ref={ref} style={styles} onPress={() => setWordLength()}>
      <View style={{width: '100%', height: '100%', position: 'absolute', zIndex: 10, backgroundColor: isHovered ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0)'}}></View>
      <CustomText style={[statisticsPage.selectText, { zIndex: 20 }]}>{val} BURTI</CustomText>
    </Pressable>
  )
}
