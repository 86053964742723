import React, { useEffect, useState, useContext } from 'react';
import { Animated, View } from 'react-native';
import { styles } from './styles';
import { ILetter, EStates } from './types';
import { BOX_MARGIN, FLIP_ANIMATION_DURATION, FLIP_ANIMATION_DELAY } from './constants';
import { ThemeContext } from '@contexts/themeContext';

const stateStyleMap = new Map<EStates, any>([
  [EStates.empty, styles.box_empty],
  [EStates.correct, styles.box_correct],
  [EStates.partial, styles.box_partial],
  [EStates.filled, styles.box_filled]
]);

export const FilledLetter = ({ state = EStates.empty, letter, letterIndex = 0, letterSize = 32 }: ILetter) => {
  const { styles } = useContext(ThemeContext);
  const [animation,] = useState(new Animated.Value(0));
  const letterStyles = styles.components.grid.letter;

  const stateColorMap = new Map<EStates, any>([
    [EStates.correct, styles.colors.gameplay.bgTileCorrect],
    [EStates.partial, styles.colors.gameplay.bgTilePartial],
    [EStates.filled, styles.colors.gameplay.bgTileWrong]
  ]);

  useEffect(() => {
    setTimeout(() => {
      Animated.timing(animation, {
        toValue:1,
        duration: FLIP_ANIMATION_DURATION,
        useNativeDriver: false
      }).start( () => {
        Animated.timing(animation,{
          toValue:2,
          duration: FLIP_ANIMATION_DURATION,
          useNativeDriver: false
        }).start()
      })
    }, letterIndex * FLIP_ANIMATION_DELAY)
  }, [letterIndex])

  const boxInterpolation = animation.interpolate({
    inputRange: [0, 1, 2],
    outputRange:['0deg', '90deg', '0deg']
  })

  const boxInterpolationColor = animation.interpolate({
    inputRange: [0.99, 1, 2],
    outputRange:['rgba(0,0,0,0)', stateColorMap.get(state), stateColorMap.get(state)]
  })

  const boxInterpolationTextColor = animation.interpolate({
    inputRange: [0.99, 1, 2],
    outputRange:[styles.colors.common.textPrimary, styles.colors.gameplay.textCorrect, styles.colors.gameplay.textCorrect]
  })

  const boxInterpolationBorder = animation.interpolate({
    inputRange: [0, 1, 2],
    outputRange:[1, 0, 0]
  })
  
  let animatedStyle = {
    transform: [{ rotateY: boxInterpolation }],
    backgroundColor: boxInterpolationColor
  }
  
  return (
    <View style={[letterStyles.container, { width: letterSize + (BOX_MARGIN * 2), height: letterSize + (BOX_MARGIN * 2)}]}>
      <Animated.View style={[letterStyles.box, { ...animatedStyle}, { width: letterSize, height: letterSize, borderWidth: boxInterpolationBorder }]}>
          <Animated.Text style={[letterStyles.text, { color: boxInterpolationTextColor }]}>{letter?.toUpperCase()}</Animated.Text>
      </Animated.View>
    </View>
  );
}
