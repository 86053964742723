import React, { useEffect, useRef, useContext } from 'react';
import { Animated, Pressable, Text, View } from 'react-native';
import { CustomText } from '../CustomText';
import { URLButton } from '../URLButton';
import { Statistics } from './Statistics';
import { Countdown } from '../Countdown';
import { Ionicons } from '@expo/vector-icons'; 
import { Share } from '../Share';
import { EPage } from '@contexts/types';
import { ThemeContext } from '@contexts/themeContext';

export const StatisticsModule = ({settings, isOpen, close, gameState, navigation}: any) => {
  const { styles } = useContext(ThemeContext);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isOpen) {
      Animated.timing(
        fadeAnim,
        {
          toValue: 1,
          duration: 500,
          useNativeDriver: false
        }
      ).start();
    } else {
      Animated.timing(
        fadeAnim,
        {
          toValue: 0,
          duration: 0,
          useNativeDriver: false
        }
      ).start();
    }
  }, [isOpen])

  if (!isOpen) {
    return <></>
  }

  return (
    <Animated.View style={{ backgroundColor: styles.colors.common.blurColor, top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', zIndex: 200, opacity: fadeAnim }}>
      <View style={{ width: '90%', maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto', backgroundColor: styles.colors.common.background, shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 30}}>
        {
          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 10, marginBottom: 10 }}>
            <CustomText style={{ color: 'white', fontSize: 20, marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', paddingLeft: 20 }}>
              {
                gameState.isEnd
                ? <Text style={{color: styles.colors.common.textPrimary, marginLeft: 'auto', marginRight: 'auto'}}>Pareizā atbilde ir {<URLButton url={`https://tezaurs.lv/${gameState.correctWord}`}>{gameState.correctWord}</URLButton>}</Text>
                : <></>
              }
            </CustomText>
            <View style={{flex: 1, maxWidth: 60}}>
              <Pressable onPress={() => close()} style={{width: 40, height: 40, marginRight: 10, zIndex: 400, marginLeft: 'auto'}}>
                <Ionicons
                  selectable={false}
                  name="close"
                  size={40} 
                  onPress={() => close()}
                  color={styles.colors.common.textSecondary}
                />
              </Pressable>
            </View>
          </View>
        }
        <Statistics wordLength={settings.wordLength} close={() => close()}/>
        { gameState.isEnd && <>
          <View style={{ flexDirection: 'row', marginTop:20, marginBottom: 15}}>
            <View style={{ flex: 1 }}>
              <View style={{ margin: 'auto' }}>
                <Countdown/>
              </View>
            </View>
            <View style={{ width: 1, backgroundColor: styles.colors.common.primary }}/>
            <View style={{ flex: 1 }}>
              <Share settings={settings}/>
            </View>
          </View>
          </>
          }
          <View style={{ width: '100%', marginBottom: 15}}>
            <Pressable onPress={() => navigation.navigate('customSelect')}>
              {/* <View style={{ backgroundColor: '#538d4e', width: '100%', height: 60, marginLeft: 'auto', marginRight: 'auto' }}> */}
                <CustomText style={{ color: styles.colors.common.textPrimary, textAlign: 'center', fontSize: 14, marginLeft: 'auto', marginRight: 'auto', textTransform: 'uppercase', alignItems: 'center'}}>
                  Minēt citus vārduļus
                </CustomText>
            </Pressable>
          </View>
      </View>
    </Animated.View>
  )
}
