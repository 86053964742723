import React, { useEffect, useState, useContext, useRef } from 'react';
import { Text, Pressable, Platform, Vibration } from 'react-native';
import { ThemeContext } from '@contexts/themeContext';
import { useHover, useFocus, useActive } from 'react-native-web-hooks';

const MAX_BUTTON_COUNT = 13;
const BUTTON_MARGIN = 2;
const BUTTON_WIDTH_PERCENTAGE = 99 / MAX_BUTTON_COUNT - 1 ;

enum EKeyStates {
  'unused',
  'correct',
  'partial',
  'used'
}

enum EClickState {
  "DOWN",
  "HOLD",
  "UP"
}

export const getKeyState = (keyValues: any, key: string) => {
  if (keyValues?.correct?.includes(key.toLowerCase())) return EKeyStates.correct;
  if (keyValues?.partial?.includes(key.toLowerCase())) return EKeyStates.partial;
  if (keyValues?.used?.includes(key.toLowerCase())) return EKeyStates.used;
  return EKeyStates.unused;
}

export const buildUsedLetters: any = (correctWord: string, guesses: string[]) => {
  const result: any = {
    used: [],
    correct: [],
    partial: [],
    unused: []
  };

  guesses.forEach(guess => {
    Array.from(guess).forEach((letter: string, index) => {
      if (correctWord[index] == letter && !result.correct.includes(letter)) {
        result.correct.push(letter);
      }
      if (correctWord.includes(letter) && !result.partial.includes(letter)) {
        result.partial.push(letter);
      }
      if (!result.used.includes(letter)) {
        result.used.push(letter);
      }
    })
  });

  return result;
}

export const Key = ({ buttonWidth, value, isNotBold, children, state, onClick }: any) => {
  const clickState = useRef(EClickState.UP);
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocus = useFocus(ref);
  const isActive = useActive(ref);

  const { styles } = useContext(ThemeContext);

  const componentStyles = styles.components.keyboard;

  const keyUnused = [componentStyles.keyButton, componentStyles.key.key_unused, {minWidth: buttonWidth - 3}];
  const keyCorrect = [componentStyles.keyButton, componentStyles.key.key_correct, {minWidth: buttonWidth - 3}];
  const keyPartial = [componentStyles.keyButton, componentStyles.key.key_partial, {minWidth: buttonWidth - 3}];
  const keyUsed = [componentStyles.keyButton, componentStyles.key.key_used, {minWidth: buttonWidth - 3}];

  const keyTextUnused = [componentStyles.key.keyTextBold, componentStyles.key.key_text_unused];
  const keyTextCorrect = [componentStyles.key.keyTextBold, componentStyles.key.key_text_correct];
  const keyTextPartial = [componentStyles.key.keyTextBold, componentStyles.key.key_text_partial];
  const keyTextUsed = [componentStyles.key.keyTextBold, componentStyles.key.key_text_used];

  const keyStateMap = new Map<EKeyStates, any>([
    [EKeyStates.unused, keyUnused],
    [EKeyStates.correct, keyCorrect],
    [EKeyStates.partial, keyPartial],
    [EKeyStates.used, keyUsed]
  ])

  const keyTextStateMap = new Map<EKeyStates, any>([
    [EKeyStates.unused, keyTextUnused],
    [EKeyStates.correct, keyTextCorrect],
    [EKeyStates.partial, keyTextPartial],
    [EKeyStates.used, keyTextUsed]
  ])

  const handleClickEvent = (enableVibrate: boolean) => {
    if (enableVibrate) Vibration.vibrate(1);
    setTimeout(() => {
      clickState.current = EClickState.UP;
      onClick(value);
    }, 0)
  }

  // const handleClickDown = () => {
  //   Vibration.vibrate([1,1,1,1]);
  //   // clickState.current = EClickState.DOWN;
  // }

  // const darkenButton = isHovered || clickState.current == EClickState.DOWN;
  const darkenButton = isHovered || isActive || isFocus;
  const keyStyles = [keyStateMap.get(state) || keyUnused, {zIndex: 100}];
  
  if (darkenButton && (state == EKeyStates.unused || !state)) {
    keyStyles.push({
      backgroundColor: 'rgba(0,0,0,0.25)'
    })
  }

  return (
    <Pressable
      onPress={() => handleClickEvent(true)}
      onLongPress={() => handleClickEvent(false)}
      delayLongPress={442}
      hitSlop={50}
      ref={ref}
      style={keyStyles}
    >
      <Text selectable={false} style={[keyTextStateMap.get(state) || keyTextUnused]}>{children || value.toUpperCase()}</Text>
    </Pressable>
  )
}