import React, { useEffect, useState, useContext, useCallback } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { CustomText, CustomTextRegular } from '../../components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { postProcessGuesses } from '../../helpers/words';
import { getDate } from '../../helpers/date';
import { Button } from '../../components/Button';
import { WordGrid } from '../WordGrid';
import { getDataFunctionsObject } from "../../helpers/data";
import { Ionicons } from '@expo/vector-icons';
import HistoricGame from './Game';

const monthMap = new Map([
  ['01', 'Janvāris'],
  ['02', 'Februāris'],
  ['03', 'Marts'],
  ['04', 'Aprīlis'],
  ['05', 'Maijs'],
  ['06', 'Jūnijs'],
  ['07', 'Jūlijs'],
  ['08', 'Augusts'],
  ['09', 'Septembris'],
  ['10', 'Oktobris'],
  ['11', 'Novembris'],
  ['12', 'Decembris']
])

export const History = ({wordLength}: any) => {
  const [histories, setHistories] = useState<any>([]);
  const [selected, setSelected] = useState<any>();
  const {styles} = useContext(ThemeContext);
  const [DF,] = useState(() => getDataFunctionsObject());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    setHistories([]);
    DF.getGameLogs(wordLength).then((res: any) => {
      setIsLoading(false);

      res.sort((a: any, b: any) => {
        const date1 = a.date.split('/').reverse().join('/');
        const date2 = b.date.split('/').reverse().join('/');
        return date1 > date2 ? 1 : -1;
      });
      setHistories(res);
    });
  }, [wordLength]);

  if (isLoading) {
    return <><ActivityIndicator color={styles.colors.common.primary} /></>;
  }

  if (histories.length == 0) {
    return (<></>)
  }

  // let prevMonth = "";

  const historiesList = JSON.parse(JSON.stringify(histories))?.reverse();

  return (
    <View style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 40, overflow: 'hidden' }}>
      <CustomText style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 20, marginTop: 20, marginBottom: 10}}>Izspēlētās spēles</CustomText>
      {
        historiesList.map((gameData: any, index: number) => {
          if (!gameData || !gameData.gameState || !gameData.gameState.correctWord || !gameData.gameState.guessList) {
            return <></>;
          }

          // const isNewMonth = prevMonth && prevMonth !== gameData.date.split("/")[1];
          // prevMonth = gameData.date.split("/")[1];

          // {
          //   isNewMonth 
          //   ? <CustomText key={`${wordLength}-${index}-1`} style={{ color: styles.colors.common.textSecondary, textAlign: 'left', fontSize: 16, marginTop: 20, marginBottom: 10}}>
          //       {gameData.date.split("/")[2]} {monthMap.get(gameData.date.split("/")[1])}
          //     </CustomText> 
          //   : <></>
          // }

          return (
            <HistoricGame
              key={`${wordLength}-${index}`}
              select={() => {
                if (selected == `${wordLength}-${index}`) {
                  setSelected('-');
                } else {
                  setSelected(`${wordLength}-${index}`)
                }
              }}
              isSelected={selected == `${wordLength}-${index}`}
              gameData={gameData}
              guessData={postProcessGuesses(gameData.gameState.correctWord, gameData.gameState.guessList, '', wordLength)}
            />);
        })
      }
    </View>
  )
}