import React, { useContext, useRef } from 'react';
import { Button, StyleSheet, View } from 'react-native';
import {Header } from '@components';
import { CustomText, CustomTextRegular } from '@components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { EPage } from '@contexts/types';
import { Ionicons } from '@expo/vector-icons'; 
import { getDataFunctionsObject } from "@helpers/data";
import Constants from "expo-constants";
import * as Analytics from 'expo-firebase-analytics';

export default function Instruction({navigation}: any) {
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;
  const { styles } = useContext(ThemeContext);

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ maxWidth: 400, width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
        <View style={{ marginTop: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomText style={{ color: 'white', marginBottom: 20, fontSize: 20 }}>
            Kā spēlēt:
          </CustomText>
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 20 }}>
            Uzmini <CustomText>VĀRDULI</CustomText> sešos mēģinājumos.
          </CustomTextRegular>
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 20 }}>
            Katram minējumam jābūt esošam piecu burtu vārdam. Piespied ievadīšanas pogu, lai apstiprinātu minējumu.
          </CustomTextRegular>
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 20 }}>
            Pēc katra minējuma mainās burtu fona krāsa, lai parādītu cik precīzs bija tavs minējums.
          </CustomTextRegular>
          <View style={{ width: '100%', height: 2, backgroundColor: '#666666' }} />
          <CustomText style={{ color: styles.colors.common.textPrimary, marginBottom: 20 }}>
            Piemēri:
          </CustomText>
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 10 }}>
            Burts 'V' ir vārdā un atrodas pareizajā vietā.
          </CustomTextRegular>
          <View style={{flexDirection:'row', flexWrap:'wrap', marginBottom: 20 }}>
            <ExampleLetter style={{backgroundColor: styles.colors.gameplay.bgTileCorrect}} textStyle={{ color: styles.colors.gameplay.textCorrect }}>V</ExampleLetter>
            <ExampleLetter>Ā</ExampleLetter>
            <ExampleLetter>R</ExampleLetter>
            <ExampleLetter>D</ExampleLetter>
            <ExampleLetter>S</ExampleLetter>
          </View>

          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 10 }}>
            Burts 'E' ir vārdā, bet nepareizajā vietā.
          </CustomTextRegular>
          <View style={{flexDirection:'row', flexWrap:'wrap', marginBottom: 20 }}>
            <ExampleLetter>D</ExampleLetter>
            <ExampleLetter>I</ExampleLetter>
            <ExampleLetter style={{backgroundColor: styles.colors.gameplay.bgTilePartial}} textStyle={{ color: styles.colors.gameplay.textPartial }}>E</ExampleLetter>
            <ExampleLetter>N</ExampleLetter>
            <ExampleLetter>A</ExampleLetter>
          </View>

          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 10 }}>
            Burts 'Z' nav vārdā.
          </CustomTextRegular>
          <View style={{flexDirection:'row', flexWrap:'wrap', marginBottom: 20 }}>
            <ExampleLetter>F</ExampleLetter>
            <ExampleLetter>R</ExampleLetter>
            <ExampleLetter>Ā</ExampleLetter>
            <ExampleLetter style={{backgroundColor: styles.colors.gameplay.bgTileWrong, color: styles.colors.common.textPrimary}} textStyle={{ color: styles.colors.gameplay.textUnused }}>Z</ExampleLetter>
            <ExampleLetter>E</ExampleLetter>
          </View>

          <View style={{ width: '100%', height: 2, backgroundColor: '#666666' }} />
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginTop: 20 }}>
            Katru dienu būs jauns <CustomText>VĀRDULIS</CustomText>!
          </CustomTextRegular>
          
          <View style={{ marginBottom: 10, width: '100%', paddingLeft: 'auto', paddingRight: 'auto', marginTop: 30 }}>
            <Button
              title={"Spēlēt"}
              onPress={() => {
                try {
                  Analytics.logEvent('TutorialAccept');
                } catch (e) {
                  console.error("Firebase error");
                }
                DF.setTutorialAccepted();
                // updateAccept(Constants?.manifest?.version || "");
                navigation.navigate('game5');
              }}
              color={styles.colors.common.primary}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const ExampleLetter = ({ children, style, textStyle }: any) => {
  const { styles } = useContext(ThemeContext);

  return (
      <View style={[{ width: 40, height: 40, borderColor: styles.colors.common.textPrimary, borderWidth: 1, marginRight: 5 }, style]}>
        <CustomText style={[textStyle || {color: styles.colors.common.textPrimary}, { fontSize: 24, margin: 'auto', textAlign: "center" }]}>
          { children }
        </CustomText>
      </View>
  )
}


const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#121213"
  },
});
