// Temporary fix for EXPO error "ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new API, your app will behave as if it's running React 17"
// Once error fixed upstream, delete this file and change package.json "main" back to:
// "main": "node_modules/expo/AppEntry.js",
import 'expo/build/Expo.fx';
import { AppRegistry, Platform } from 'react-native';
import withExpoRoot from 'expo/build/launch/withExpoRoot';

import App from './App';
import { createRoot } from "react-dom/client";

AppRegistry.registerComponent('main', () => withExpoRoot(App));
if (Platform.OS === 'web') {
  const rootTag = createRoot(document.getElementById('root') ?? document.getElementById('main'));
  const RootComponent = withExpoRoot(App);
  rootTag.render(<RootComponent />);
}