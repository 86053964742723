import { createContext, useRef, useState, useContext } from 'react';
import { Notification } from '../components/Notification';

export const NotificationContext = createContext({
  notification: { show: false, text: '' },
  show: (value: string) => {},
  trigger: (value: string) => {},
  hide: () => {},
});

export default ({
  children,
}: {
  children: React.ReactNode
}) => {
  const refTimeout = useRef<any>();
  const [notification, setNotification] = useState({ show: false, text: '' });

  const showNotification = (value: string) => {
    setTimeout(() => {
      setNotification({ show: true, text: value });
      if (refTimeout.current) {
        clearTimeout(refTimeout.current);
      }
      refTimeout.current = setTimeout(() => {
        setNotification({ show: false, text: value })
      }, 2000)
    }, 0);
  }

  const triggerNotification = (value: string) => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }
    refTimeout.current = setTimeout(() => {
      setNotification({ show: true, text: value });
    }, 500);
  }

  const hideNotification = () => {
    if (refTimeout.current) {
      clearTimeout(refTimeout.current);
    }
    setTimeout(() => {
      setNotification({ show: false, text: '' });
    }, 0);
  }

  return (
    <NotificationContext.Provider value={{
      notification,
      trigger: triggerNotification,
      show: showNotification,
      hide: hideNotification
    }}>
      <Notification data={notification} />
      {children}
    </NotificationContext.Provider>
  )
}