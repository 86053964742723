import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Header } from '@components';
import { ThemeContext } from '@contexts/themeContext';
import { useAuth } from '@contexts/authContext';
import { Button as CustomButton } from '@components/Button';

import { useConfig } from '@hooks/useConfig';

export default function Menu({navigation}: {navigation: any}) {
  const { user, login } = useAuth();
  const { config } = useConfig();
  const { styles } = useContext(ThemeContext);

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation} />
      <View style={{ maxWidth: 400, width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="Jaunumi"
            disabled={!config.isAuthEnabled}
            emoji={
              <Ionicons
                selectable={false}
                name="newspaper-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.secondary}
            hoverColor={styles.colors.common.secondaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('news')}
          />
        </View>
        {
        !user && <>
          <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
            <CustomButton
              text="Pieslēgties"
              disabled={!config.isAuthEnabled}
              emoji={
                <Ionicons
                  selectable={false}
                  name="log-in-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                />
              }
              color={styles.colors.common.primary}
              hoverColor={styles.colors.common.primaryHover}
              textColor={styles.colors.common.btnText}
              onClick={() => login()}
            />
          </View>
          <View style={{ marginBottom: 25, marginLeft: 'auto', marginRight: 'auto', width: '90%' }}></View>
        </>
        }
        {/* {
          user && <>
            <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
              <CustomButton
                text="Mans Profils"
                disabled={!config.isAuthEnabled}
                emoji={
                  <Ionicons
                    selectable={false}
                    name="person-circle-outline"
                    size={30}
                    color={styles.colors.common.textPrimary}
                  />
                }
                color={styles.colors.common.secondary}
                hoverColor={styles.colors.common.secondaryHover}
                textColor={styles.colors.common.btnText}
                onClick={() => navigation.navigate('profile')}
              />
            </View>
            <View style={{ marginBottom: 25, marginLeft: 'auto', marginRight: 'auto', width: '90%' }}></View>
          </>
        } */}
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="Šīs dienas puzle"
            disabled={!config.isGame5Enabled}
            emoji={
              <Ionicons
                selectable={false}
                name="today-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.primary}
            hoverColor={styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('game5')}
          />
        </View>
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="Pielāgota puzle"
            disabled={
              !config.isGame4Enabled
              && !config.isGame5Enabled
              && !config.isGame6Enabled
              && !config.isGame7Enabled
              && !config.isGame8Enabled
            }
            emoji={
              <Ionicons
                selectable={false}
                name="calendar-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.primary}
            hoverColor={styles.colors.common.primaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('customSelect')}
          />
        </View>
        <View style={{ marginBottom: 25, marginLeft: 'auto', marginRight: 'auto', width: '90%' }}></View>
        {
          !user && <>
            <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
              <CustomButton
                text="Statistika"
                disabled={!config.isStatisticsEnabled}
                emoji={
                  <Ionicons
                    selectable={false}
                    name="stats-chart-outline"
                    size={30}
                    color={styles.colors.common.textPrimary}
                  />
                }
                color={styles.colors.common.secondary}
                hoverColor={styles.colors.common.secondaryHover}
                textColor={styles.colors.common.btnText}
                onClick={() => navigation.navigate('statistics')}
              />
            </View>
          </>
        }
        <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="Iestatījumi"
            emoji={
              <Ionicons
                selectable={false}
                name="settings-outline"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.secondary}
            hoverColor={styles.colors.common.secondaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('settings')}
          />
        </View>
        {
          user && 
          <View style={{ marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
            <CustomButton
              text="Ieteikumi / Kļūdu ziņojumi"
              disabled={!config.isContactEnabled}
              emoji={
                <Ionicons
                selectable={false}
                name="chatbubble-outline"
                size={30}
                color={styles.colors.common.textPrimary}
                />
              }
              color={styles.colors.common.secondary}
              hoverColor={styles.colors.common.secondaryHover}
              textColor={styles.colors.common.btnText}
              onClick={() => navigation.navigate('contact')}
              />
          </View>
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#121213"
  },
});
