import React, { useContext, useRef } from 'react';
import { Platform, Pressable, Share as ShareNative, Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { CustomText } from '../CustomText';
import * as Clipboard from 'expo-clipboard';
import { buildGuess } from '../../helpers/words';
import { EStates } from '../WordGrid/Letter/types';
import { useNotification } from '../../hooks/useNotification';
import { getDate } from '../../helpers/date';
import * as Analytics from 'expo-firebase-analytics';
import { ThemeContext } from '@contexts/themeContext';
import { getDataFunctionsObject } from "../../helpers/data";

const buildGameGrid = (gameState: any) => {
  let output = "";
  const correctWord = gameState.correctWord;
  const guessList = gameState.guessList;
  guessList.forEach((guess: string) => {
    const builtGuess = buildGuess(correctWord, guess);
    builtGuess && builtGuess.letters.forEach((letter: any) => {
      if (letter.state === EStates.correct) {
        output += '🟩';
      } else if (letter.state === EStates.partial) {
        output += '🟨';
      } else {
        output += '⬛';
      }
    })
    output += '\n';
  })

  output += '\n';
  output += 'https://vardulis.lv #vārdulis';

  return output;
}

export const Share = ({settings}: any) => {
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;

  const {styles} = useContext(ThemeContext);
  const {show} = useNotification();

  const triggerShare = async () => {
    let clipboardText = "";

    try {
      Analytics.logEvent('Share');
    } catch (e) {
      console.error("Firebase error");
    }
    
    DF.getTodaysGame(settings.wordLength).then((res) => {
      const currentDate = getDate(new Date());
      clipboardText += `Vārdulis ${currentDate} ${res.gameState.guessList.length}/6\n\n`;

      const gameGrid = buildGameGrid(res.gameState);
      clipboardText += gameGrid;

      if (Platform.OS === 'web' && window) {
        copyToClipboard(clipboardText);
      } else {
        shareMobile(clipboardText);
      }
    })

  }

  const shareMobile = async (clipboardText: any) => {
    try {
      ShareNative.share({
        message: clipboardText
      });
    } catch(e) {
      console.error(e);
    }
  }

  const copyToClipboard = (clipboardText: any) => {
    Clipboard.setString(clipboardText);
    show("Teksts nokopēts");
  };

  return (
    <View style={{ marginLeft: 20, marginRight: 20, overflow: 'hidden' }}>
      <View style={{ width: '100%'}}>
        <Pressable onPress={() => triggerShare()}>
          <View style={{ backgroundColor: styles.colors.common.primary, width: '100%', minHeight: 60, marginLeft: 'auto', marginRight: 'auto' }}>
            <CustomText style={{ paddingLeft: 5, paddingRight: 5, color: 'white', textAlign: 'center', fontSize: 22, marginTop: 'auto', marginBottom: 'auto', textTransform: 'uppercase' }}>
              Dalīties<Text style={{paddingLeft: 5}}><Ionicons name="share-social-sharp" size={24} color="white" /></Text>
            </CustomText>
          </View>
        </Pressable>
      </View>
    </View>
  )
}
