import { useState, useContext, useEffect } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import { Letter } from './Letter';
import { FilledRow, EmptyRow, ActiveRow } from './Row';
import { ISettings } from '../../types';
// import GuessAnimationContext from '@contexts/guessAnimationContext';

import { IRow } from './Row/types';
import { ERowTypes } from './Row/types';


interface IWordGridProps {
  guesses: IRow[],
  letterSizeCustom?: number
}

export const WordGrid = ({ guesses, letterSizeCustom }: IWordGridProps) => {
  const windowWidth = Dimensions.get('window').width;
  const letterSize = letterSizeCustom || (windowWidth / guesses[0]?.guess?.letters?.length) - 6;
  const [squareSize, setSquareSize] = useState(200);

  const generateRows = (guessRow: IRow, squareSize: number) => {
    if (guessRow.rowType === ERowTypes.filled) {
      return <FilledRow guess={guessRow.guess} letterSize={squareSize} />
    }
    if (guessRow.rowType === ERowTypes.active) {
      return <ActiveRow guess={guessRow.guess} letterSize={squareSize} />
    }
    if (guessRow.rowType === ERowTypes.empty) {
      return <EmptyRow guess={guessRow.guess} letterSize={squareSize} />
    }
    return <></>;
  }

  return (
    <View
      style={{
        marginTop: 'auto',
        marginBottom: 'auto',
        paddingTop: 10,
        paddingBottom: 10,
      }}
      onLayout={(event) => {
        const {x, y, width, height} = event.nativeEvent.layout;

        const guessCount = guesses.length;
        const guessLetterCount = guesses[0].guess.letters.length;
       
        const maxWidth = width / guessLetterCount;
        const maxHeight = height / guessCount;

        let value = 30;

        if (maxWidth > maxHeight) {
          value = maxHeight - 7;
        } else {
          value = maxWidth - 7;
        }

        if (value < 30) {
          setSquareSize(30);
        } else {
          setSquareSize(value);
        }
      }}
    >
      {
        guesses?.map((guess, rowIndex) => (
          <View style={styles.row} key={rowIndex}>
            {generateRows(guess, squareSize)}
          </View>
        ))
      }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    padding: 5
  },
  row: {
    width: '100%',
    flexDirection: "row",
    justifyContent: "center",
    maxWidth: 400,
    margin: "auto"
  }
});
