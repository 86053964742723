import { getFirestore, setDoc, getDoc, getDocs, doc, query, where, collection, orderBy, limit } from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Firebase from "../Firebase";
import { getDate } from "../date";
import DataFunctionsLocal from "./local";
import { httpsCallable } from 'firebase/functions';
import { TGameLog } from "./types";

const firebase = new Firebase();

enum EGameState {
  IN_PROGRESS = "IN_PROGRESS",
  WIN = "WIN",
  LOSE = "LOSE",
  ABANDONED = "ABANDONED"
}

enum EGameMode {
  DAILY = "DAILY",
  CUSTOM = "CUSTOM"
}

enum EResponseCodes {
  SUCCESS = 200,
  ERROR = 500,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  BAD_REQUEST = 400
}

enum EGameTypes {
  "4X6" = "4X6",
  "5X6" = "5X6",
  "6X6" = "6X6",
  "7X6" = "7X6",
  "8X6" = "8X6"
}

enum ESubmitResponses {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  ERROR_OUT_OF_SYNC = "ERROR_OUT_OF_SYNC"
}

export default class DataFunctionsFirestore extends DataFunctionsLocal {
  constructor() {
    super();
  }

  public clearAllData = async () => {

  }


  public getNews = async () => {
    const res: any = [];
    const q = query(collection(firebase.firestore, "news"), orderBy("created", "desc"), limit(10));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const news = doc.data();
      res.push(news);
    });

    return res;
  }


  // public clearTodaysGame = async () => {

  // }
  public getMyReports = async () => {
    const res: Array<any> = [];
    const q = query(
      collection(firebase.firestore, "reports"),
      where("uid", "==", firebase.auth.currentUser.uid),
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const report = doc.data();
      res.push({
        ...report,
        id: doc.id
      });
    });

    return res;
  }

  public getAllReports = async () => {
    const res: Array<any> = [];
    const q = query(
      collection(firebase.firestore, "reports"),
      orderBy("updated", "desc")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const report = doc.data();
      res.push({
        ...report,
        id: doc.id
      });
    });

    return res;
  }

  public getReport = async (id: string) => {
    try {
      const docRef = doc(firebase.firestore, "reports", id);
      const reportDoc = await getDoc(docRef);
      const report: any = reportDoc.data();

      const updateReportReadTime = httpsCallable(firebase.functions, 'updateReportReadTime');
      updateReportReadTime({reportId: id, uid: firebase.auth.currentUser.uid});

      return report;
    } catch(e) {
      console.log("Error getting document: " + e);
    }
    return {};
  }

  public submitReport = async (message: string): Promise<{ ok: boolean; id?: string, message: string }> => {
    const submitReport = httpsCallable(firebase.functions, 'submitReport');
    try {
      const res: any = await submitReport({
        message
      });

      if (res.data.code == EResponseCodes.SUCCESS) {
        return {
          ok: true,
          id: res.data.id,
          message: "Ziņojums nosūtīts!"
        };
      } else if (res.data.code == EResponseCodes.ERROR) {
        console.error(res.data.response);
        return {
          ok: false,
          message: "Nevarēja nosūtīt ziņojumu!"
        };
      } else {
        console.error(res.data.response);
      }
    } catch (e) {
      console.log(e);
    }
    return {
      ok: false,
      message: "Nevarēja nosūtīt ziņojumu!"
    };
  }

  public findUserReport = async (): Promise<{ ok: boolean; id?: string, message: string }> => {
    var resID: string = "";
    try {
      const q = query(
        collection(firebase.firestore, "reports"),
        where("uid", "==", firebase.auth.currentUser.uid),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.id) {
          resID = doc.id;
        }
      });

      return {
        ok: true,
        id: resID,
        message: "Atrada lietotāja ziņojumu!"
      };
    } catch(e) {
      console.log("Error getting document: " + e);
    }
    return {
      ok: false,
      message: "Nevarēja atrast lietotāja ziņojumu!"
    };
  }

  public updateReport = async (reportId: string, message: string): Promise<{ ok: boolean; id?: string, message: string; }> => {
    const updateReport = httpsCallable(firebase.functions, 'submitReport');
    try {
      const res: any = await updateReport({
        reportId,
        message
      });

      if (res.data.code == EResponseCodes.SUCCESS) {
        return {
          ok: true,
          id: res.data.id,
          message: "Ziņojums nosūtīts!"
        };
      } else if (res.data.code == EResponseCodes.ERROR) {
        console.error(res.data.response);
        return {
          ok: false,
          message: "Nevarēja nosūtīt ziņojumu!"
        };
      } else {
        console.error(res.data.response);
      }
    } catch (e) {
      console.log(e);
    }
    return {
      ok: false,
      message: "Nevarēja nosūtīt ziņojumu!"
    };
  }

  public getGame = async (gameId: string): Promise<TGameLog> => {
    console.log("Called: getGame");
    
    let res: TGameLog = {
      isWin: false,
      turns: 0,
      isSynced: false,
      gameState: {
        word: "",
        guessList: [],
        isWrong: false,
        isEnd: true,
        showStats: false,
        correctWord: '',
      },
      date: '',
    };

    const docRef = doc(firebase.firestore, "games", gameId);
    const gameDoc = await getDoc(docRef);
    const game = gameDoc.data();

    if (gameDoc.exists()) {
      res = {
        isWin: game?.state == EGameState.WIN,
        turns: game?.words.length,
        isSynced: game?.isSynced,
        gameState: {
          word: "",
          guessList: game?.words,
          isWrong: false,
          isEnd: true,
          showStats: false,
          correctWord: game?.correctWord
        },
        date: game?.date
      };
    } 
    return res;
  }

  public getGameLogs = async (wordLength: number) => {
    console.log("Called: getGameLogs");
    const res: TGameLog[] = [];

    const q = query(
      collection(firebase.firestore, "games"),
      where("uid", "==", firebase.auth.currentUser.uid),
      where("type", "==", `${wordLength}X6`),
      where("state", "in", [EGameState.WIN, EGameState.LOSE])
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const game = doc.data();
      res.push({
        isWin: game.state == EGameState.WIN,
        turns: game.words.length,
        isSynced: game.isSynced,
        gameState: {
          word: "",
          guessList: game.words,
          isWrong: false,
          isEnd: true,
          showStats: false,
          correctWord: game.correctWord
        },
        date: game.date
      });
    });

    return res;
  }

  public getGameScore = async (wordLength: number) => {
    console.log("Called: getGameScore");
    var turnCount = 6;

    console.log();

    try {
      const docRef = doc(firebase.firestore, "stats", `${firebase.auth.currentUser.uid}_${wordLength}X6`);
      const statsDoc = await getDoc(docRef);
      const stats = statsDoc.data();
      
      const resObj = {
        wins: stats?.wins || 0,
        losses: stats?.losses || 0,
        turns: stats?.turns || new Array(turnCount).fill(0),
        win_streak: stats?.win_streak || 0,
        max_win_streak: stats?.max_win_streak || 0,
        last_score: stats?.last_score || 0,
      }
      
      return resObj;
    } catch (e) {
      console.error("Error getting document:", e);
    }

    const resObj = {
      wins: 0,
      losses: 0,
      turns: new Array(turnCount).fill(0),
      win_streak: 0,
      max_win_streak: 0,
      last_score: 0
    }
    
    return resObj;
  }

  // public getLastReloadTime = async (wordLength: number) => {

  // }

  // public getTheme = async () => {

  // }

  public getTodaysGame = async (letterCount: number) => {
    console.log("Called: getTodaysGame");
    const dateObject = new Date();
    const date = getDate(dateObject);
    const gameId = `${firebase.auth.currentUser.uid}_${letterCount}X6_${date.replace(/\//g, "-")}`;

    try {
      const docRef = doc(firebase.firestore, "games", gameId);
      const gameDoc = await getDoc(docRef);
      
      if (!gameDoc.exists()) {
        console.log("No such document!");
        return;
      }
      
      const gameData = gameDoc.data();
      
      return {
        "gameState": {
          "word": "",
          "guessList": gameData.words,
          "isWrong": false,
          "isEnd": gameData.state == EGameState.WIN || gameData.state == EGameState.LOSE,
          "showStats": false,
          "correctWord": gameData.correctWord || "",
          "input": ""
        },
        "date": date,
      }
    } catch (e) {
      return;
    }
  }

  // public getTutorialAccepted = async () => {

  // }

  // public getUpdateAccept = async () => {

  // }

  // public setUpdateAccept = async () => {

  // }

  public setGameScore = async () => {
    console.log("Called: setGameScore");

  }

  // public setLastReloadTime = async () => {

  // }

  // public setTheme = async () => {

  // }
  public getUserData = async (uid: string) => {
    const getUserData = httpsCallable(firebase.functions, 'getUserData');
    const res: any = await getUserData({uid});

    if (res.data.response == ESubmitResponses.SUCCESS) {
      return res.data;
    } else {
      return {};
    }
  }

  public setTodaysGame = async (wordLength: number, gameState: any): Promise<{ok: boolean, message: string}> => {
    console.log("Called: setTodaysGame");
    
    const submitWord = httpsCallable(firebase.functions, 'submitWord');
    const date = new Date();

    try {
      const res: any = await submitWord({
        type: `${wordLength}X6`,
        date: getDate(date),
        mode: EGameMode.DAILY,
        words: gameState.guessList,
        word: gameState.guessList[gameState.guessList.length - 1],
        correctWord: gameState.correctWord
      });

      if (res.data.response == ESubmitResponses.SUCCESS) {
        return {
          ok: true,
          message: "Spēles dati veiksmīgi saglabāti"
        };
      } else if (res.data.response == ESubmitResponses.ERROR_OUT_OF_SYNC) {
        console.error(res.data.response);
        return {
          ok: false,
          message: "Spēle nav sasinhronizēta starp ierīcēm. Lūdzu pārlādē šo lapu!"
        };
      } else {
        console.error(res.data.response);
      }
    } catch (e) {
      console.log(e);
    }
    return {
      ok: false,
      message: "Nevarēja saglabāt datus. Nepieciešams interneta savienojums!"
    };
  }

  // public setTutorialAccepted = async () => {

  // }

  // public updateToNewFormat = async () => {

  // }
  

  public syncLocalData = async () => {
    const syncData = httpsCallable(firebase.functions, 'syncData');

    // const res = super.getGameLogs(5);
    // console.log(res);

    const getLocalGameLogs = async (wordLength: number) => {
      try {
        const value = await AsyncStorage.getItem(`game.${wordLength}.logs`);
        if (value !== null) {
          return JSON.parse(value);
        }
      } catch (error) {
        // Error retrieving data
      }
      return [];
    }

    const data = {
      4: await getLocalGameLogs(4),
      5: await getLocalGameLogs(5),
      6: await getLocalGameLogs(6),
      7: await getLocalGameLogs(7),
      8: await getLocalGameLogs(8),
    }

    try {
      const res: any = await syncData(data);
      if (res.data) {
        return {
          ok: true,
          message: "Spēles dati tiek sinhronizēti... Tie drīzumā būs pieejami Jūsu profilā."
        };
      }
    } catch (e) {
      console.log(e);
    }
  }
}