import React, { useEffect, useState, useContext } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { CustomText, CustomTextRegular } from '../../components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { getDataFunctionsObject } from "../../helpers/data";

export const Statistics = ({wordLength, close}: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [gameScore, setGameScore] = useState<any>();
  const {styles} = useContext(ThemeContext);
  const [DF,] = useState(() => getDataFunctionsObject());

  useEffect(() => {
    setGameScore(null);
    setIsLoading(true);
    DF.getGameScore(wordLength).then((res: any) => {
      setIsLoading(false);
      if (!res) {
        close();
      }
      setGameScore(res);
    }).catch(() => {
      // setIsLoading(false);
    });
  }, [wordLength]);

  if (isLoading) {
    return (<><ActivityIndicator color={styles.colors.common.primary} style={{marginTop: 40, marginBottom: 40}}/></>)
  }

  if (!gameScore) {
    return (
      <CustomText style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 16, marginTop: 20, marginBottom: 10}}>Nav neviena izspēlēta spēle priekš {wordLength} burtu vārduļa</CustomText>
    )
  }
  console.log(gameScore);

  const winPercentage = Math.round(100 * (gameScore.wins / (gameScore.losses + gameScore.wins)));

  return (
    <View style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, overflow: 'hidden' }}>
      <CustomText style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 20, marginTop: 20, marginBottom: 10}}>Statistika</CustomText>
      <View style={{flexDirection: 'row', width: '100%'}}>
        <Score description={"Izspēlētas"}>{gameScore.losses + gameScore.wins}</Score>
        <Score description={"Uzvaras %"}>{isNaN(winPercentage) ? '0' : winPercentage}</Score>
        <Score description={"Uzvaru sērija"}>{gameScore.win_streak}</Score>
        <Score description={"Lielākā uzvaru sērija"}>{gameScore.max_win_streak}</Score>
      </View>
      <CustomText style={{ color: styles.colors.common.textSecondary, textAlign: 'center', fontSize: 20, marginTop: 20, marginBottom: 10}}>Uzvaru sadalījums</CustomText>
      <Graph turnWinCounts={gameScore.turns} lastScore={gameScore.last_score}/>
    </View>
  )
}

const Score = ({ children, description }: any) => {
  const { styles } = useContext(ThemeContext);
  return (
    <View style={{flex: 1}}>
      <CustomText style={{ color: styles.colors.common.textPrimary, textAlign: 'center', fontSize: 30}}>{children}</CustomText>
      <CustomTextRegular style={{ color: styles.colors.common.textPrimary, textAlign: 'center', fontSize: 14}}>{description}</CustomTextRegular>
    </View>
  )
}

const Graph = ({turnWinCounts, lastScore}: any) => {
  const maxTurnWinCounts = Math.max(...turnWinCounts);

  return (
    <View>
      { 
        turnWinCounts.map((turnWinCount: number, index: number) => {
          return (<Bar key={index} maxTurnWinCounts={maxTurnWinCounts} turnWinCount={turnWinCount} lastScore={lastScore} index={index}/>)
        })
      }
    </View>
  )
}

const Bar = ({maxTurnWinCounts , turnWinCount, lastScore, index}: any) => {
  const { styles } = useContext(ThemeContext);
  const maxValue = !maxTurnWinCounts ? 100 : maxTurnWinCounts;
  const barColor = (lastScore - 1) == index ? styles.colors.common.primary : styles.colors.common.secondary;
  return (
    <View style={{width: '100%', height: 20, marginTop: 5, flexDirection: 'row'}}>
      <CustomText style={{color: styles.colors.common.textPrimary, width: 15}}>{index + 1}</CustomText>
      <View style={{flex: 1}}>
        <View style={{minWidth: 20, width: `${Math.round((turnWinCount / maxValue) * 100)}%`, height: '100%', backgroundColor: barColor}}>
          <CustomTextRegular style={{position: 'absolute', color: styles.colors.common.btnText, right: 5}}>
            {turnWinCount || '0'}
          </CustomTextRegular>
        </View>
      </View>
    </View>
  )
}