import React, { useContext, useState, useRef, useEffect } from 'react';
import { Button, StyleSheet, View, TextInput } from 'react-native';
import { CustomText, Header } from '@components';
import { CustomTextRegular } from '@components/CustomText';
import { ThemeContext } from '@contexts/themeContext';
import { Ionicons } from '@expo/vector-icons'; 
import { getDataFunctionsObject } from "@helpers/data";
import Firebase from "@helpers/Firebase";
import { getDateTime } from '@helpers/date';
import { Button as CustomButton } from '@components/Button';
import { useAuth } from '@contexts/authContext';

const firebase = new Firebase();

const useReports = () => {
  const [DF,] = useState(() => getDataFunctionsObject());
  const [reports, setReports] = useState<any>();

  useEffect(() => {
    const getReport = async () => {

      DF.getAllReports().then((report) => {
        setReports(report);
      });
    }
    getReport();
  }, []);

  return reports;
}

export default function ContactReport({navigation, route}: {navigation: any, route: any}) {
  const { styles } = useContext(ThemeContext);
  const reports = useReports();
  const { user } = useAuth();

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ width: 400, marginLeft: 'auto', marginRight: 'auto', marginTop: 20, alignContent: "center" }}>
      {
        reports && reports.map((report: any) => {
          return (
            <CustomButton
              key={report.id}
              text={`${getDateTime(new Date(report.updated))}`}
              emoji={
                <Ionicons
                  selectable={false}
                  name="chatbox-ellipses-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                />
              }
              color={report.messages[report.messages.length - 1].uid == user.uid ? styles.colors.common.secondary : styles.colors.common.primary}
              hoverColor={styles.colors.common.secondaryHover}
              textColor={styles.colors.common.btnText}
              style={{ flex: 1, marginTop: 20 }}
              onClick={() => navigation.navigate('contact', { reportId: report.id })}
            />
          )
        })
      }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: "#121213"
  },
});
