
export default (colors: any) => ({
  container: {
    backgroundColor: colors.common.background3,
    padding: 10,
    color: colors.common.textPrimary
  },
  containerLong: {
    backgroundColor: colors.common.background3,
    padding: 10,
    color: colors.common.textPrimary,
    height: 150
  }
})
