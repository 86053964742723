import Firebase from "../Firebase";
import { getDocs, query, collection, orderBy, limit } from "firebase/firestore";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { IScore } from '../types';
import { getDate } from '../date';
import { httpsCallable } from 'firebase/functions';

const firebase = new Firebase();

type TGameLog = {
  isWin: boolean,
  turns: number,
  isSynced?: boolean,
  gameState: {
    word: "",
    guessList: string[],
    isWrong: boolean,
    isEnd: boolean,
    showStats: boolean,
    correctWord: string
  },
  date: string
}

enum EGameMode {
  DAILY = "DAILY",
  CUSTOM = "CUSTOM"
}

enum ESubmitResponses {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  ERROR_OUT_OF_SYNC = "ERROR_OUT_OF_SYNC"
}

export default class DataFunctionsLocal {

  public getNews = async () => {
    const res: any = [];
    const q = query(collection(firebase.firestore, "news"), orderBy("created", "desc"), limit(10));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      const news = doc.data();
      res.push(news);
    });

    return res;
  }


  public getTutorialAccepted = async () => {
    try {
      const value = await AsyncStorage.getItem(`tutorial.accept`);
      if (value !== null) {
        return value == 'true';
      }
    } catch (error) {
      // Error retrieving data
    }
    return false;
  }

  public setTutorialAccepted = () => {
    AsyncStorage.setItem(`tutorial.accept`, 'true');
  }

  public setUpdateAccept = (version: string) => {
    AsyncStorage.setItem(`update.accept`, version);
  }

  public getUpdateAccept = async () => {
    try {
      const value = await AsyncStorage.getItem(`update.accept`);
      if (value !== null) {
        return value;
      }
    } catch (error) {
      // Error retrieving data
    }
    return '';
  }

  public setTheme = (theme: string) => {
    AsyncStorage.setItem(`settings.theme`, theme);
  }

  public getTheme = async () => {
    try {
      const value = await AsyncStorage.getItem(`settings.theme`);
      if (value) {
        return parseInt(value);
      }
    } catch (error) {
      // Error retrieving data
    }
    return 1;
  }

  public clearAllData = () => {
    console.log("Cleared all async storage data");
    AsyncStorage.clear();
  }

  public clearTodaysGame = (wordLength: number) => {
    console.log("Cleared todays game data");
    AsyncStorage.setItem(`game.${wordLength}.today`, '');
  }

  public setLastReloadTime = async (wordLength: number) => {
    const inputData = getDate(new Date());

    try {
      await AsyncStorage.setItem(
        `game.${wordLength}.lastReload`,
        JSON.stringify(inputData)
      );
    } catch (error) {
      // Error saving data
    }
  }

  public getLastReloadTime = async (wordLength: number) => {
    try {
      const value = await AsyncStorage.getItem(`game.${wordLength}.lastReload`);
      if (value !== null) {
        return JSON.parse(value);
      }
    } catch (error) {
      // Error retrieving data
    }

    return getDate(new Date());
  };

  public getGameScore = async (wordLength: number) => {
    const logs = await this.getGameLogs(wordLength);
    let turnCount = 6;

    const resObj = {
      wins: 0,
      losses: 0,
      turns: Array.from(Array(turnCount)).map(() => 0),
      win_streak: 0,
      max_win_streak: 0,
      last_score: 0,
    }

    let winStreak = 0;
    let maxWinStreak = 0;

    logs.forEach((log: any) => {
      if (log.gameState.correctWord == 'diēzs' && !log.isWin) {
        return;
      }
      if (log.isWin) {
        resObj.wins++;
        winStreak++;
        if (maxWinStreak < winStreak) {
          maxWinStreak = winStreak;
        }
      } else {
        resObj.losses++;
        winStreak = 0;
      }
      if (log.isWin) {
        resObj.turns[log.turns - 1]++;
      }
    }, this);

    resObj.win_streak = winStreak;
    resObj.max_win_streak = maxWinStreak;
    if (logs[logs.length - 1] && logs[logs.length - 1].turns) {
      resObj.last_score = logs[logs.length - 1].turns;
    }

    return resObj;

    // try {
    //   const value = await AsyncStorage.getItem(`game.${wordLength}.score`);
    //   if (value !== null) {
    //     return JSON.parse(value);
    //   }
    // } catch (error) {
    //   // Error retrieving data
    // }

    // return {
    //   wins: 0,
    //   losses: 0,
    //   turns: Array.from(Array(turnCount)).map(() => 0),
    //   win_streak: 0,
    //   max_win_streak: 0,
    //   last_score: 0,
    // };
  };

  public getGameLogs = async (wordLength: number) => {
    try {
      const value = await AsyncStorage.getItem(`game.${wordLength}.logs`);
      if (value !== null) {
        const savedLogs = JSON.parse(value);
        // return savedLogs;
        // Get unique logs based on date
        const res = savedLogs.filter((log: any, index: number) => {
          return savedLogs.findIndex((log2: any) => getDate(new Date(log2.date)) == getDate(new Date(log.date))) == index;
        });
        return res;
      }
    } catch (error) {
      // Error retrieving data
    }
    return [];
  };

  public getTodaysGame = async (wordLength: number) => {
    try {
      const value = await AsyncStorage.getItem(`game.${wordLength}.today`);
      if (value !== null) {
        return JSON.parse(value);
      }
    } catch (error) {
      // Error retrieving data
    }
    return {};
  };

  public setTodaysGame = async (wordLength: number, gameState: any): Promise<{ok: boolean, message: string}> => {
    if (!await this.checkDateIsMatch(wordLength) || await this.checkDateIsDuplicate(wordLength, gameState)) {
      return {
        ok: false,
        message: "Nevar saglabāt citas dienas spēli!"
      };
    }

    const isEnd = gameState.guessList.length >= 6 || gameState.correctWord == gameState.guessList[gameState.guessList.length - 1];
    const isWin = gameState.correctWord == gameState.guessList[gameState.guessList.length - 1];
    
    const inputData = {
      gameState,
      date: getDate(new Date())
    }

    try {
      await AsyncStorage.setItem(
        `game.${wordLength}.today`,
        JSON.stringify(inputData)
      );
    } catch (error) {
      // Error saving data
    }

    console.log(gameState);

    if (isEnd) {
      try {
        const res = httpsCallable(firebase.functions, 'submitGuestGame')({
          type: `${wordLength}X6`,
          date: getDate(new Date()),
          mode: EGameMode.DAILY,
          words: gameState.guessList,
          correctWord: gameState.correctWord
        });
        console.log(res);
      } catch (e) {
        console.error(e);
      }
    }

    return {
      ok: true,
      message: "Spēles dati veiksmīgi saglabāti"
    };
  }

  public setGameScore = async (isWin: boolean, turns: number, settings: any) => {
    if (!this.checkDateIsMatch(settings.wordLength)) return;

    const scores: IScore = await this.getGameScore(settings.wordLength);
    const todaysGame = await this.getTodaysGame(settings.wordLength);
    const logs: any[] = await this.getGameLogs(settings.wordLength);

    const inputData = {
      isWin,
      turns,
      gameState: todaysGame.gameState,
      date: new Date()
    }

    logs.push(inputData);

    try {
      await AsyncStorage.setItem(
        `game.${settings.wordLength}.logs`,
        JSON.stringify(logs)
      );
    } catch (error) {
      // Error saving data
    }
    
    if (isWin) {
      scores.last_score = turns;
      scores.wins++;
      scores.turns[turns - 1]++;
      scores.win_streak++;
      if (!scores.max_win_streak || scores.win_streak > scores.max_win_streak) {
        scores.max_win_streak = scores.win_streak;
      }
    } else {
      scores.last_score = -1;
      scores.win_streak = 0;
      scores.losses++;
    }

    try {
      await AsyncStorage.setItem(
        `game.${settings.wordLength}.score`,
        JSON.stringify(scores)
      );
    } catch (error) {
      // Error saving data
    }
  }

  private checkDateIsMatch = async (wordLength: number) => {
    return getDate(new Date()) == await this.getLastReloadTime(wordLength);
  }

  private checkDateIsDuplicate = async (wordLength: number, gameState: any) => {
    let currentGameDate = getDate(new Date());
    const logs: any[] = await this.getGameLogs(wordLength);

    if (logs.length > 0) {
      return logs.filter((log: any) => {
        let lastGameDate = getDate(new Date(log.date));
        if (currentGameDate == lastGameDate && gameState.correctWord == log.gameState.correctWord) {
          return true;
        } else {
          return false;
        }
      }).length > 0;
    }

    return false;
  };
  
  private removeDuplicate = async (wordLength: number, gameState: any) => {
    var currentGameDate = getDate(new Date());

    this.getGameLogs(wordLength).then((logs: any[]) => {
      if (logs.length > 0) {
        const newLogs = logs.filter((log: any) => {
          var lastGameDate = getDate(log.date);
          if (currentGameDate == lastGameDate && gameState.correctWord == log.gameState.correctWord) {
            return false;
          } else {
            return true
          }
        });

        console.log(newLogs);
      }
    });
  }

  public updateToNewFormat = async () => {
    try {
      const value = await AsyncStorage.getItem(`normal.today`);
      if (value !== null) {
        AsyncStorage.setItem(
          `game.5.today`,
          value
        );
        AsyncStorage.removeItem('normal.today');
      }
    } catch (error) {
      console.error(error);
      console.error("Unable to update normal.today");
    }
    
    try {
      const value = await AsyncStorage.getItem(`normal.score`);
      if (value !== null) {
        AsyncStorage.setItem(
          `game.5.score`,
          value
        );
        AsyncStorage.removeItem('normal.score');
      }
    } catch (error) {
      console.error(error);
      console.error("Unable to update normal.score");
    }

    try {
      const value = await AsyncStorage.getItem(`normal.logs`);
      if (value !== null) {
        AsyncStorage.setItem(
          `game.5.logs`,
          value
        );
        AsyncStorage.removeItem('normal.logs');
      }
    } catch (error) {
      console.error(error);
      console.error("Unable to update normal.logs");
    }

    try {
      const value = await AsyncStorage.getItem(`normal.lastReload`);
      if (value !== null) {
        AsyncStorage.setItem(
          `game.5.lastReload`,
          value
        );
        AsyncStorage.removeItem('normal.lastReload');
      }
    } catch (error) {
      console.error(error);
      console.error("Unable to update normal.lastReload");
    }
  }

  public syncLocalData = async (): Promise<any> => {
    console.error("Must log in to sync data!")
    return {
      ok: false,
      message: "Kā tu šeit nokļuvi? Tev jāpieslēdzās savam kontam, lai sinhronizētu datus!"
    };
  }

  public getMyReports = async () => {
    var res: Array<any> = [];
    return res;
  }

  public getAllReports = async () => {
    var res: Array<any> = [];
    return res;
  }

  public getUserData = async (uid: string) => {
    return {};
  }
  
  public getReport = async (id: string) => {
    return {};
  }

  public submitReport = async (message: string): Promise<{ ok: boolean; id?: string, message: string; }> => {
    return {
      ok: false,
      message: "Kā tu šeit nokļuvi? Tev jāpieslēdzās savam kontam, lai iesniegtu ziņojumu!"
    };
  };

  public updateReport = async (reportId: string, message: string): Promise<{ ok: boolean; id?: string, message: string; }> => {
    return {
      ok: false,
      message: "Kā tu šeit nokļuvi? Tev jāpieslēdzās savam kontam, lai iesniegtu ziņojumu!"
    };
  };

  public findUserReport = async (): Promise<{ ok: boolean; id?: string, message: string }> => {
    return {
      ok: false,
      message: "Nevarēja atrast lietotāja ziņojumu!"
    };
  }

  public getGame = async (gameId: string): Promise<TGameLog> => {
    console.log("Called: getGame");
    return {
      isWin: false,
      turns: 0,
      isSynced: false,
      gameState: {
        word: "",
        guessList: [],
        isWrong: false,
        isEnd: true,
        showStats: false,
        correctWord: '',
      },
      date: '',
    };
  }
}
