import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Dimensions, StyleSheet, Platform, View } from 'react-native';
import { Key, getKeyState } from './Key';
import { Ionicons } from '@expo/vector-icons';
import { ThemeContext } from '@contexts/themeContext';

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    height: "100%",
    padding: 5
  },
  row: {
    width: '100%',
    flexDirection: "row",
    justifyContent: "center",
    maxWidth: 600,
    margin: "auto"
  }
});

const MAX_BUTTON_COUNT_IN_ROW = 12;

export const Keyboard = React.memo(({ onKeyPress, usedLetters }: any) => {
  const [buttonWidth, setButtonWidth] = useState(30);
  const { styles: contextStyles } = useContext(ThemeContext);
  const componentStyles = contextStyles.components.keyboard;

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  
  const handleBrowserClickEvent = useCallback((event: any) =>  {
    if (['ā','š','e','ē','r','t','u','ū','i','ī','o','p','a','s','d','f','g','ģ','h','j','k','ķ','l','ļ','enter','z','ž','c','č','v','b','n','ņ','m','backspace'].includes(event.key.toLowerCase())) {
      onKeyPress(event.key);
    }
  }, [onKeyPress]);

  useEffect(() => {
    if (Platform.OS === 'web' && window) {
      window.addEventListener('keydown', handleBrowserClickEvent);
      return () => {
        removeEventListener('keydown', handleBrowserClickEvent);
      }
    }
  }, [handleBrowserClickEvent])

  return (
    <View
      style={{
        height: '100%',
        backgroundColor: ( windowWidth > windowHeight ? 'rgba(0,0,0,0)' : componentStyles.key.key_unused.backgroundColor )
      }}
    >
      <View
        onLayout={(event) => {
          const {x, y, width, height} = event.nativeEvent.layout;
          setButtonWidth(width / MAX_BUTTON_COUNT_IN_ROW);
        }}
        style={styles.row}
      >
        <Key buttonWidth={buttonWidth} value={'ā'} state={getKeyState(usedLetters, 'Ā')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'š'} state={getKeyState(usedLetters, 'Š')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'e'} state={getKeyState(usedLetters, 'E')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ē'} state={getKeyState(usedLetters, 'Ē')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'r'} state={getKeyState(usedLetters, 'R')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'t'} state={getKeyState(usedLetters, 'T')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'u'} state={getKeyState(usedLetters, 'U')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ū'} state={getKeyState(usedLetters, 'Ū')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'i'} state={getKeyState(usedLetters, 'I')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ī'} state={getKeyState(usedLetters, 'Ī')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'o'} state={getKeyState(usedLetters, 'O')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'p'} state={getKeyState(usedLetters, 'P')} onClick={onKeyPress}/>
      </View>
      <View style={styles.row}>
        <Key buttonWidth={buttonWidth} value={'a'} state={getKeyState(usedLetters, 'A')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'s'} state={getKeyState(usedLetters, 'S')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'d'} state={getKeyState(usedLetters, 'D')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'f'} state={getKeyState(usedLetters, 'F')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'g'} state={getKeyState(usedLetters, 'G')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ģ'} state={getKeyState(usedLetters, 'Ģ')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'h'} state={getKeyState(usedLetters, 'H')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'j'} state={getKeyState(usedLetters, 'J')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'k'} state={getKeyState(usedLetters, 'K')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ķ'} state={getKeyState(usedLetters, 'Ķ')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'l'} state={getKeyState(usedLetters, 'L')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ļ'} state={getKeyState(usedLetters, 'Ļ')} onClick={onKeyPress}/>
      </View>
      <View style={styles.row}>
        <Key buttonWidth={buttonWidth * 1.5} value={'Enter'} onClick={onKeyPress} isNotBold={true}>
          <Ionicons name="arrow-forward-outline" size={32} color={contextStyles.colors.gameplay.textUnused} />
        </Key>
        <Key buttonWidth={buttonWidth} value={'z'} state={getKeyState(usedLetters, 'Z')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ž'} state={getKeyState(usedLetters, 'Ž')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'c'} state={getKeyState(usedLetters, 'C')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'č'} state={getKeyState(usedLetters, 'Č')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'v'} state={getKeyState(usedLetters, 'V')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'b'} state={getKeyState(usedLetters, 'B')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'n'} state={getKeyState(usedLetters, 'N')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'ņ'} state={getKeyState(usedLetters, 'Ņ')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth} value={'m'} state={getKeyState(usedLetters, 'M')} onClick={onKeyPress}/>
        <Key buttonWidth={buttonWidth * 1.5} value={'Backspace'} onClick={onKeyPress} isNotBold={true}>
          <Ionicons name="backspace-outline" size={32} color={contextStyles.colors.gameplay.textUnused} />
        </Key>
      </View>
    </View>
  )
});
