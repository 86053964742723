import { StyleSheet, Text, View } from 'react-native';

export const CustomText = (props: any) => {
  return (
    <Text {...props}>
      <Text style={styles.text} selectable={props.selectable || false}>{props.children}</Text>
    </Text>
  );
}

export const CustomTextRegular = (props: any) => {
  return (
    <Text {...props}>
      <Text style={styles.textRegular} selectable={props.selectable || false} >{props.children}</Text>
    </Text>
  );
}

const styles = StyleSheet.create({
  text: {
    fontFamily: "clearsansbold",
  },
  textRegular: {
    fontFamily: "clearsans",
  }
});
