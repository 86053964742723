const BOX_SIZE = 65;
const BOX_MARGIN = 3;

export default (colors: any) => ({
  container: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: colors.common.background
  },
  letter: {
    container: {
      flexDirection: "column",
      maxHeight: BOX_SIZE + (BOX_MARGIN * 2),
      maxWidth: BOX_SIZE + (BOX_MARGIN * 2),
      position: "relative",
      textAlign: "center",
    },
    box: {
      marginLeft: BOX_MARGIN,
      marginTop: BOX_MARGIN,
      maxWidth: BOX_SIZE,
      maxHeight: BOX_SIZE,
      borderColor: "#3a3a3c",
      borderWidth: 1,
      position: "absolute"
    },
    box_empty: {
      flexDirection: "column",
      margin: 2
    },
    box_correct: {
      flexDirection: "column",
      margin: 2,
      backgroundColor: colors.gameplay.bgTileCorrect
    },
    box_partial: {
      flexDirection: "column",
      margin: 2,
      backgroundColor: colors.gameplay.bgTilePartial
    },
    box_filled: {
      flexDirection: "column",
      margin: 2,
      backgroundColor: colors.gameplay.bgTileWrong
    },
    box_text_empty: {
      color: colors.gameplay.textTileUnused,
    },
    box_text_correct: {
      color: colors.gameplay.textTileCorrect,
    },
    box_text_partial: {
      color: colors.gameplay.textTilePartial,
    },
    box_text_filled: {
      color: colors.gameplay.textTileWrong,
    },
    text: {
      fontSize: 32,
      lineHeight: 32,
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingBottom: 1,
      fontFamily: "clearsansbold",
      textShadowColor: 'rgba(0,0,0,0.2)',
      textShadowOffset: {width: 2, height: 2},
      textShadowRadius: 2
    },
    text_empty: {
      color: colors.gameplay.textTileEmpty,
      fontSize: 32,
      lineHeight: 32,
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingBottom: 1,
      fontFamily: "clearsansbold",
      textShadowColor: 'rgba(0,0,0,0.2)',
      textShadowOffset: {width: 2, height: 2},
      textShadowRadius: 2
    },
    textContainer: {
      height: '100%',
      justifyContent:'center'
    }
  }
})
