import React, { useCallback, useContext } from 'react';
import { Linking, Button, Alert, View } from 'react-native';
import { ThemeContext } from '@contexts/themeContext';

export const URLButton = ({ url, children, style }: any) => {
  const { styles } = useContext(ThemeContext);

  const handlePress = useCallback(async () => {
    // Checking if the link is supported for links with custom URL scheme.
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      // Opening the link with some app, if the URL scheme is "http" the web link should be opened
      // by some browser in the mobile
      await Linking.openURL(url);
    } else {
      Alert.alert(`Nevar atvērt šo URL: ${url}`);
    }
  }, [url]);

  return (
    <View style={style}>
      <Button
        title={children}
        onPress={handlePress}
        color={styles.colors.common.primary}
      />
    </View>
  );
};
