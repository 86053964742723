export enum EPage {
  "menu",
  "contact",
  "game",
  "settings",
  "profile",
  "statistics",
  "customSelect",
  "customGame",
  "customGameMenu",
  "instruction"
}
