
export default (colors: any) => (
  {
    centeredView: {
      backgroundColor: colors.common.blurColor,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 22,
    },
    headerView: {
      alignItems: 'center'
    },
    modalView: {
      margin: 20,
      backgroundColor: colors.common.background,
      padding: 25,
      paddingTop: 20,
      paddingBottom: 20,
      shadowColor: '#000',
      minWidth: '40%',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.75,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      borderRadius: 5,
      padding: 10,
      elevation: 2,
    },
    buttonOpen: {
      backgroundColor: colors.common.primary,
    },
    buttonClose: {
      marginTop: 20,
      backgroundColor: colors.common.primary,
    },
    textStyle: {
      color: colors.common.btnText,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    modalText: {
      marginBottom: 15,
      color: colors.common.textPrimary,
      textAlign: 'center',
    },
  }
)