import React, { useContext, useState, useEffect, useRef } from 'react';
import { Alert, Button, StyleSheet, Switch, View, Pressable, Platform } from 'react-native';
import { Header, CustomText } from '@components';
import { ThemeContext } from '@contexts/themeContext';
import { Ionicons } from '@expo/vector-icons'; 
import { ETheme } from '@contexts/types';
import { Button as CustomButton } from '@components/Button';
import { useAuth } from '@contexts/authContext';
import { getDataFunctionsObject } from "@helpers/data";
import { useConfig } from '@hooks/useConfig';

export default function Settings({ navigation }: { navigation: any }) {
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;
  const { user } = useAuth();
  const { styles, theme, setTheme } = useContext(ThemeContext);
  // const [ selectedTheme, setSelectedTheme ] = useState<ETheme>(theme == ETheme.light || theme == ETheme.contrastLight ? ETheme.light : ETheme.dark);
  const [ isLight, setIsLight ] = useState(theme == ETheme.light || theme == ETheme.contrastLight ? true : false);
  const [ isHighContrast, setIsHighContrast ] = useState(theme == ETheme.contrastLight || theme == ETheme.contrastDark ? true : false);
  const { config } = useConfig();

  const openConfirmation = () => {
    if (Platform.OS === 'web' && window) {
      if (confirm("Vai esi pārliecināts, ka vēlies izdzēst saglabātos spēļu datus?\nIzdzēšot spēles datus tiks zaudēta izspēlēto spēļu statistika.")) {
        DF.clearAllData();
      }
    } else {
      Alert.alert(
        "Spēles datu dzēšana",
        "Vai esi pārliecināts, ka vēlies izdzēst saglabātos spēļu datus? Izdzēšot spēles datus tiks zaudēta izspēlēto spēļu statistika.",
        [
          {
            text: "Dzēst",
            onPress: () => DF.clearAllData()
          },
          {
            text: "Atcelt",
            onPress: () => console.log("Cancel Pressed"),
            style: "cancel"
          }
        ]
      );
    }
  }

  // const notificationPermission = () => {
  //   Firebase.askForPermissioToReceiveNotifications();
  // }

  const getThemeName = (theme: ETheme) => {
    switch (theme) {
      case ETheme.dark:
        return "Tumšā tematika"
      case ETheme.light:
        return "Gaišā tematika"
      case ETheme.contrastLight:
        return "Gaišā tematika";
      case ETheme.contrastDark:
        return "Tumšā tematika";
      default:
        return "Tumšā tematika"
    }
  }

  useEffect(() => {
    if (isLight) {
      if (isHighContrast) {
        setTheme(ETheme.contrastLight);
      } else {
        setTheme(ETheme.light);
      }
    } else {
      if (isHighContrast) {
        setTheme(ETheme.contrastDark);
      } else {
        setTheme(ETheme.dark);
      }
    }
  }, [isLight, isHighContrast])

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ maxWidth: 400, width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
        {/* <View style={{width: '100%', marginBottom: 10}}>
          <Button 
            title={"Ziņot par kļūdu"}
            onPress={() => {}}
            color={'#333333'}
          />
        </View> */}
        <View style={{width: '100%'}}>
          <View style={{flexDirection: 'row', padding: 8}}>
            <View style={{paddingRight: 20}}>
              <Switch
                trackColor={{ false: styles.colors.common.background2, true: styles.colors.common.primary }}
                thumbColor={styles.colors.common.secondary}
                onValueChange={() => setIsLight(!isLight)}
                value={!isLight}
              />
            </View>
            <CustomText style={{color: styles.colors.common.textPrimary}}>Tumšā tematika</CustomText>
          </View>
        </View>
        <View style={{width: '100%', marginTop: 10}}>
          <View style={{flexDirection: 'row', padding: 8}}>
            <View style={{paddingRight: 20}}>
              <Switch
                trackColor={{ false: styles.colors.common.background2, true: styles.colors.common.primary }}
                thumbColor={styles.colors.common.secondary}
                onValueChange={() => setIsHighContrast(!isHighContrast)}
                value={isHighContrast}
              />
            </View>
            <CustomText style={{color: styles.colors.common.textPrimary}}>Augsta kontrasta režīms</CustomText>
          </View>
        {/* <View style={{ marginTop: 40, marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '100%', paddingLeft: 'auto', paddingRight: 'auto' }}>
          <CustomButton
            text="Spēles atjauninājumu vēsture"
            emoji={
              <Ionicons
                selectable={false}
                name="list"
                size={30}
                color={styles.colors.common.textPrimary}
              />
            }
            color={styles.colors.common.secondary}
            hoverColor={styles.colors.common.secondaryHover}
            textColor={styles.colors.common.btnText}
            onClick={() => navigation.navigate('changes')}
          />
        </View> */}
          {
            user && 
            <View style={{ marginTop: 100, marginBottom: 10, marginLeft: 'auto', marginRight: 'auto', width: '90%', paddingLeft: 'auto', paddingRight: 'auto' }}>
              <CustomButton
                text="Ieteikumi / Kļūdu ziņojumi"
                disabled={!config.isContactEnabled}
                emoji={
                  <Ionicons
                  selectable={false}
                  name="chatbubble-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                  />
                }
                color={styles.colors.common.background1}
                hoverColor={styles.colors.common.background2}
                textColor={styles.colors.common.textPrimary}
                onClick={() => navigation.navigate('contact')}
                />
            </View>
          }
        {
          !user &&
          <View style={{width: '100%', marginTop: 10}}>
            <CustomButton
              text="Dzēst spēles datus"
              emoji={
                <Ionicons
                  selectable={false}
                  name="trash-bin-outline"
                  size={30}
                  color={styles.colors.common.textPrimary}
                />
              }
              color={styles.colors.common.warning}
              hoverColor={styles.colors.common.warningHover}
              textColor={styles.colors.common.btnText}
              onClick={() => openConfirmation()}
              />
          </View>
        }
        </View>
      </View>
    </View>
  );
}