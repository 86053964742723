import React, { useContext } from 'react';
import { Text, View } from 'react-native';
import { ThemeContext } from '@contexts/themeContext';
import { Ionicons } from '@expo/vector-icons'; 
import { useAuth } from '@contexts/authContext';

export const Header = ({ left, right, navigation, hideIcons = false }: any) => {
  const { user, login, logout } = useAuth();
  const { styles } = useContext(ThemeContext);
  const [, updateState] = React.useState();
  // @ts-ignore
  const forceUpdate = React.useCallback(() => updateState({}), []);

  React.useEffect(() => {
    // Reload component for when pressing back button
    const willFocusSubscription = navigation.addListener('focus', () => {
      forceUpdate();
    });
    return willFocusSubscription;
  }, []);

  return (
    <View style={styles.components.header.container}>
      <View style={styles.components.header.subContainer}>
        <View style={[styles.components.header.contentLeft]}>
          <Ionicons
            selectable={false}
            name="ios-menu"
            size={32}
            style={{ padding: 8 }}
            onPress={() => navigation.navigate('news')}
            color={styles.colors.common.textPrimary}
          />
        </View>
        {
          navigation.canGoBack() && !hideIcons &&
          <View style={[styles.components.header.contentLeft, {left: 40}]}>
            <Ionicons
              selectable={false}
              name="arrow-back-outline"
              size={32}
              style={{ padding: 8 }}
              onPress={() => navigation.goBack()}
              color={styles.colors.common.textPrimary}
              />
          </View>
        }
        {
          !hideIcons && left && left?.map((element: any, key: number) => {
            return (
              <View key={key} style={[styles.components.header.contentLeft, {left: 40 * (key + 2)}]}>
                {element}
              </View>
            )
          })
        }
        <Text selectable={false} style={[styles.components.header.content]}>{"Vārdulis"}</Text>
        {
          !hideIcons && right && right?.map((element: any, key: number) => {
            return (
              <View key={key} style={[styles.components.header.contentRight, { right: (35 * key) + 35 }]}>
                {element}
              </View>
            )
          })
        }
        <View style={[styles.components.header.contentRight,  { right: 0 }]}>
          {
            !hideIcons && (
              <>
                <View style={{ flexDirection:'row', flexWrap:'wrap' }}>
                  <Ionicons
                    selectable={false}
                    name="settings-outline"
                    size={28}
                    style={{ padding: 8, right: 70, display: "flex" }}
                    onPress={() => navigation.navigate('settings')}
                    color={styles.colors.common.textPrimary}
                  />
                  {
                    user ? (
                      <Ionicons
                        selectable={false}
                        name="person-circle-outline"
                        size={28}
                        style={{ padding: 8, right: 70, display: "flex" }}
                        onPress={() => navigation.navigate('profile')}
                        color={styles.colors.common.textPrimary}
                      />
                    ) : !hideIcons && (
                      <Ionicons
                        selectable={false}
                        name="log-in-outline"
                        size={28}
                        style={{ padding: 8 }}
                        onPress={() => login()}
                        color={styles.colors.common.textPrimary}
                      />)
                  }
                  {/* <Text
                    selectable={false}
                    style={[styles.components.header.content, { fontSize: 16, paddingBottom: 0, paddingTop: 8, paddingRight: 10, bottom: 0, verticalAlign: 'bottom', alignSelf: 'flex-end', textAlign: 'left'}]}
                    onPress={() => navigation.navigate('profile')}
                  >
                    {user?.displayName?.split(' ').map((val: string) => val[0])}
                  </Text> */}
                </View>
              </>
          )}
        </View>
      </View>
    </View>
  );
}
