import React, { useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { View } from 'react-native';
import { Keyboard, WordGrid, buildUsedLetters, StatisticsModule } from '@components';
import { postProcessGuesses } from '@helpers/words';
import { Ionicons } from '@expo/vector-icons'; 
import { Header } from '@components/Header';
import { useAuth } from '@contexts/authContext';

import { getDataFunctionsObject } from "../helpers/data";
import { getDate } from '@helpers/date';
import { GUESS_COUNT } from '../constants';

import GuessAnimationContext from '@contexts/guessAnimationContext';
import { useNotification } from '@hooks/useNotification';
import { ThemeContext } from '@contexts/themeContext';

import { ISettings } from '../types';
import { Dimensions } from 'react-native';

import * as Analytics from 'expo-firebase-analytics';

import * as words4long from '../../assets/words-4long-all.json';
import * as words5long from '../../assets/words-5long-all.json';
import * as words6long from '../../assets/words-6long-all.json';
import * as words7long from '../../assets/words-7long-all.json';
import * as words8long from '../../assets/words-8long-all.json';
import * as guess4long from '../../assets/words-4long-guess.json';
import * as guess5long from '../../assets/words-5long-guess.json';
import * as guess6long from '../../assets/words-6long-guess.json';
import * as guess7long from '../../assets/words-7long-guess.json';
import * as guess8long from '../../assets/words-8long-guess.json';

// @ts-ignore
const dictionary4long = words4long?.default;
// @ts-ignore
const guesses4long = guess4long?.default;

// @ts-ignore
const dictionary5long = words5long?.default;
// @ts-ignore
const guesses5long = guess5long?.default;

// @ts-ignore
const dictionary6long = words6long?.default;
// @ts-ignore
const guesses6long = guess6long?.default;

// @ts-ignore
const dictionary7long = words7long?.default;
// @ts-ignore
const guesses7long = guess7long?.default;

// @ts-ignore
const dictionary8long = words8long?.default;
// @ts-ignore
const guesses8long = guess8long?.default;

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

interface IGameState {
  word: string,
  guessList: string[],
  isWrong: boolean,
  isEnd: boolean,
  showStats: boolean,
  correctWord: string
}

enum EGameStateActions {
  'SET_INITIAL',
  'SET_WORD',
  'SET_GUESS_LIST',
  'SET_IS_WRONG_TRUE',
  'SET_IS_WRONG_FALSE',
  'SET_IS_END_TRUE',
  'SET_IS_END_FALSE',
  'SET_CORRECT_WORD',
  'ADD_LETTER',
  'BACKSPACE',
  'SHOW_STATS',
  'HIDE_STATS',
  'SUBMIT_WORD',
}

const getDictionary = (settings: ISettings) => {
  switch (settings.wordLength) {
    case 4:
      return [dictionary4long, guesses4long];
    case 5:
      return [dictionary5long, guesses5long];
    case 6:
      return [dictionary6long, guesses6long];
    case 7:
      return [dictionary7long, guesses7long];
    case 8:
      return [dictionary8long, guesses8long];
    default:
      return [dictionary5long, guesses5long];
  }
}

export default function CustomGame({ settings, navigation }: any) {
  const { styles } = useContext(ThemeContext);
  const { show, trigger, hide } = useNotification();
  const DataFunctions = useRef(getDataFunctionsObject());
  const [ dictionary, dictionaryGuesses ] = getDictionary(settings);
  const { user } = useAuth();
  let DF = DataFunctions.current;

  const gameStateReducer = (state: IGameState, action: any) => {
    switch (action.type) {
      case EGameStateActions.SET_INITIAL: {
        return { 
          ...state,
          input: action.input || "",
          guessList: action.guessList || [],
          correctWord: action.correctWord || "",
          isEnd: action.isEnd || false,
          isWrong: action.isWrong || false
        }
      }
      case EGameStateActions.SET_GUESS_LIST: {
        return { 
          ...state,
          guessList: action.guessList
        }
      }
      case EGameStateActions.SET_WORD: {
        return { 
          ...state,
          word: action.word
        }
      }
      case EGameStateActions.ADD_LETTER: {
        if ((state.word.length + 1) > action.maxWordLength) {
          return state;
        }
        return { 
          ...state,
          word: state.word + action.key
        }
      }
      case EGameStateActions.BACKSPACE: {
        return { 
          ...state,
          word: state.word.toLowerCase().slice(0, -1)
        }
      }
      case EGameStateActions.SET_IS_WRONG_TRUE: {
        return { 
          ...state,
          isWrong: true
        }
      }
      case EGameStateActions.SET_IS_WRONG_FALSE: {
        return { 
          ...state,
          isWrong: false
        }
      }
      case EGameStateActions.SET_IS_END_TRUE: {
        return { 
          ...state,
          isEnd: true
        }
      }
      case EGameStateActions.SET_IS_END_FALSE: {
        return { 
          ...state,
          isEnd: false
        }
      }
      case EGameStateActions.SET_CORRECT_WORD: {
        return { 
          ...state,
          correctWord: action?.correctWord || ""
        }
      }
      case EGameStateActions.SHOW_STATS: {
        return { 
          ...state,
          showStats: true
        }
      }
      case EGameStateActions.HIDE_STATS: {
        return { 
          ...state,
          showStats: false
        }
      }
      case EGameStateActions.SUBMIT_WORD: {
        const newState = { 
          ...state,
          word: "",
          isWrong: false,
          gameId: action.gameId,
          guessList: [...state.guessList, state.word]
        };
        return newState;
      }
      default: {
        return state;
      }
    }
  }

  const [ gameState, dispatchGameState ] = useReducer(gameStateReducer, {
    word: "",
    guessList: [],
    isWrong: false,
    isEnd: false,
    showStats: false,
    correctWord: '',
  });
  const refInterval = useRef<any>();

  const getTodaysWord = async () => {
    const startDate = new Date("03/13/2022");
    const currentDateTime = new Date();
    const yyyy = currentDateTime.getFullYear();
    let mm = '' + (currentDateTime.getMonth() + 1); // Months start at 0!
    let dd = '' + currentDateTime.getDate();
    const currentDate = new Date(`${mm}/${dd}/${yyyy}`)

    const timeSinceStart = currentDate.getTime() - startDate.getTime();
    const dayCountFromStart = Math.floor(timeSinceStart / (1000 * 3600 * 24));
    const todaysWord = dictionaryGuesses[dayCountFromStart % (Array.from(dictionaryGuesses).length)];
    
    var lastReloadTime = await DF.getLastReloadTime(settings.wordLength);

    if (getDate(currentDate) != lastReloadTime) {
      dispatchGameState({
        type: EGameStateActions.SET_INITIAL
      });
      DF.clearTodaysGame(settings.wordLength);
      DF.setLastReloadTime(settings.wordLength);
    }
    return todaysWord;
  }
  
  const onKeyPress = (key: string) => {
    if (gameState.isEnd) return;
    if (key == 'Backspace') {
      dispatchGameState({type: EGameStateActions.BACKSPACE});
      return;
    }
    if (key == 'Enter') {
      submit();
      return;
    }
    dispatchGameState({
      type: EGameStateActions.ADD_LETTER,
      key: key.toLowerCase(),
      maxWordLength: settings.wordLength
    });
  };

  const submit = () => {
    // Check if max word count reached
    if (gameState.guessList.length >= GUESS_COUNT) {
      return;
    }
    // Check if word is correct length
    if (Array.from(gameState.word).length != settings.wordLength) {
      show(`Jāievada ${settings.wordLength} burtu vārds!`);
      dispatchGameState({type: EGameStateActions.SET_IS_WRONG_TRUE});
      return;
    }
    // Check if word is in dictionary
    if (!dictionary.includes(gameState.word) && !dictionaryGuesses.includes(gameState.word)) {
      show("Jāievada latviešu valodas vārds!");
      dispatchGameState({type: EGameStateActions.SET_IS_WRONG_TRUE});
      try {
        Analytics.logEvent('WordInvalidSubmit', {
          wordLength: settings.wordLength,
          word: gameState.word,
        });
      } catch (e) {
        console.error("Firebase error");
      }
      return;
    }

    try {
      Analytics.logEvent('WordValidSubmit', {
        wordLength: settings.wordLength,
        word: gameState.word,
      });
    } catch (e) {
      console.error("Firebase error");
    }

    const newState = { 
      ...gameState,
      word: "",
      isWrong: false,
      guessList: [...gameState.guessList, gameState.word]
    };
    trigger("Pārbauda vārdu...");
    const DFPromise = DF.setTodaysGame(gameState.word.length, newState);
    DFPromise.then((res) => {
      if (res.ok) {
        dispatchGameState({type: EGameStateActions.SUBMIT_WORD});
        hide();
      } else {
        console.log("Unable to save");
        show(res.message);
      }
    });
  }

  const handleGameEnd = (isWin: boolean, turns: number) => {
    // console.log("Game Ended");
    try {
      Analytics.logEvent('GameEnd', {
        wordLength: settings.wordLength,
        isWin: isWin,
        turns: turns
      });
    } catch (e) {
      console.error("Firebase error");
    }

    let expressionList = [];
    if (isWin && turns <= 1) {
      expressionList = ["Oho!"];
    } else if (isWin && turns <= 2) {
      expressionList = ["Iespaidīgi!", "Uzvara!", "Super!"];
    } else if (isWin && turns <= 3) {
      expressionList = ["Uzvara!", "Labais!", "Super!"];
    } else if (isWin && turns <= 5) {
      expressionList = ["Neslikti!", "Cienījami!"];
    } else if (isWin) {
      expressionList = ["Knapi!"];
    } else {
      expressionList = ["Ai!", "Skāde!", "Žēl!", "Eeh!"];
    }
    show(expressionList[Math.floor(Math.random() * expressionList.length)]);

    if (gameState.isEnd == false) {
      dispatchGameState({type: EGameStateActions.SET_IS_END_TRUE});
      DF.setGameScore(isWin, turns, settings);
    }
    console.log("here")
    setTimeout(function() {
      dispatchGameState({type: EGameStateActions.SHOW_STATS});
    }, 1500);
  }

  useEffect(() => {
    getTodaysWord().then((todaysWord: string) => {
      dispatchGameState({
        type: EGameStateActions.SET_CORRECT_WORD,
        correctWord: todaysWord
      });
    });
    // Check if todays game is started
    DF.getTodaysGame(settings.wordLength).then((todaysGame: any) => {
      if (todaysGame?.date == getDate(new Date())) {
        dispatchGameState({
          type: EGameStateActions.SET_INITIAL,
          ...todaysGame.gameState
        });
      }
    });
        
    // Start interval for reloading word on new day
    refInterval.current = setInterval(() => {
      DF.getLastReloadTime(settings.wordLength).then((res: any) => {
        if (res !== getDate(new Date())) {
          dispatchGameState({
            type: EGameStateActions.SET_INITIAL
          });
          getTodaysWord().then((todaysWord: string) => {
            dispatchGameState({
              type: EGameStateActions.SET_CORRECT_WORD,
              correctWord: todaysWord
            });
          })
          DF.clearTodaysGame(settings.wordLength);
          show("Jauna diena, jauns vārdulis!");
        }
      });
    }, 5000);

    return () => clearInterval(refInterval.current);
  }, [])

  useEffect(() => {
    DF = getDataFunctionsObject();

    // Check if todays game is started
    DF.getTodaysGame(settings.wordLength).then((todaysGame: any) => {
      if (todaysGame?.date == getDate(new Date())) {
        dispatchGameState({
          type: EGameStateActions.SET_INITIAL,
          ...todaysGame.gameState
        });
      } else {
        dispatchGameState({type: EGameStateActions.SET_INITIAL});
        getTodaysWord().then((todaysWord: string) => {
          dispatchGameState({
            type: EGameStateActions.SET_CORRECT_WORD,
            correctWord: todaysWord
          });
        });
      }
    });
  }, [user])

  // useEffect(() => {
  //   DF.setTodaysGame(settings.wordLength, gameState);
  // }, [gameState]);

  useEffect(() => {
    if (gameState.isEnd) {
      setTimeout(function() {
        dispatchGameState({type: EGameStateActions.SHOW_STATS});
      }, 1500);
      return;
    }
    if (gameState.guessList[gameState.guessList.length - 1] === gameState.correctWord) {
      handleGameEnd(true, gameState.guessList.length);
      return;
    }
    if (gameState.guessList.length >= GUESS_COUNT) {
      handleGameEnd(false, GUESS_COUNT);
    }
  }, [gameState.guessList])

  if (!gameState.guessList) {
    return <>Error while loading game data.</>;
  }

  const postProcessedGuessObject = useMemo(
    () => postProcessGuesses(gameState.correctWord, gameState.guessList, gameState.word, settings.wordLength),
    [gameState.correctWord, gameState.guessList, gameState.word]
  );

  const leftMenu = [];
  if (settings.wordLength == 5) {
    leftMenu.push(
      <Ionicons
        selectable={false}
        name="information-circle-outline"
        size={28}
        style={{ padding: 8, marginTop: 4 }}
        onPress={() => navigation.navigate('instruction')}
        color={styles.colors.common.textPrimary}
      />
    );
  }

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation} left={leftMenu}/>
      {/* { gameState.isEnd && <Countdown /> } */}
      <View style={{height: screenHeight - 68, display: 'flex', flexDirection: 'column'}}>
        <View style={{ flex: 1, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <GuessAnimationContext.Provider value={{ isWrong: gameState.isWrong, setIsWrong: (value) => {
            if (value) {
              dispatchGameState({type: EGameStateActions.SET_IS_WRONG_TRUE});
            } else {
              dispatchGameState({type: EGameStateActions.SET_IS_WRONG_FALSE});
            }
          }}}>
            <WordGrid guesses={postProcessedGuessObject} />
          </GuessAnimationContext.Provider>
        </View>
        <View style={{ height: 165, marginBottom: 10 }}>
          <Keyboard
            onKeyPress={onKeyPress}
            usedLetters={buildUsedLetters(gameState.correctWord, gameState.guessList)}
          />
        </View>
      </View>

      <StatisticsModule 
        settings={settings}
        gameState={gameState}
        isOpen={gameState.showStats}
        navigation={navigation}
        close={() => dispatchGameState({type: EGameStateActions.HIDE_STATS})}
      />
    </View>
  );
}