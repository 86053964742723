import React, { useEffect, useState, useContext, useRef } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Header } from '@components';
import { Ionicons } from '@expo/vector-icons';
import { ThemeContext } from '@contexts/themeContext';
import CHANGES from "@root/changelog.json";
import { CustomText, CustomTextRegular } from '@components/CustomText';

export default ({ navigation }: any) => {
  const { styles } = useContext(ThemeContext);

  const changeList = JSON.parse(JSON.stringify(CHANGES)).reverse();

  return (
    <View style={[styles.components.page.container, { overflowY: 'scroll' }]}>
      <Header navigation={navigation}/>
      {
        changeList.map((change: any) => {
          return (
            <View key={change.version} style={{ maxWidth: 600, width: '95%', marginLeft: 'auto', marginRight: 'auto', marginTop: 40, paddingBottom: 20, borderBottomColor: styles.colors.common.textSecondary, borderBottomWidth: 1 }}>
              <View>
                <CustomText style={{ color: styles.colors.common.textPrimary, marginBottom: 10, fontSize: 16 }}>Papildinājums {change.version}</CustomText>
              </View>
              <View style={{ paddingLeft: 15 }}>
                {change.changes.map((change: any, index: number) => {
                  return (<CustomTextRegular key={index} style={{ color: styles.colors.common.textSecondary, textAlign: 'left', fontSize: 15, marginBottom: 8}}>{change}</CustomTextRegular>)
                })}
              </View>
            </View>
          )
        })
      }
    </View>
  )
}
