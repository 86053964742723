import React, { useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Header, CustomText, WordGrid } from '@components';
import { ThemeContext } from '@contexts/themeContext';

const wordleData = [
  {
      "guess": {
          "letters": [
              {
                  "state": 1,
                  "letter": "-"
              },
              {
                  "state": 1,
                  "letter": "4"
              },
              {
                  "state": 1,
                  "letter": "0"
              },
              {
                  "state": 1,
                  "letter": "4"
              },
              {
                  "state": 1,
                  "letter": "-"
              }
          ]
      },
      "rowType": 0
  },
  {
      "guess": {
          "letters": [
              {
                  "state": 1,
                  "letter": "k"
              },
              {
                  "letter": "a",
                  "state": 3
              },
              {
                  "letter": "t",
                  "state": 1
              },
              {
                  "letter": "r",
                  "state": 3
              },
              {
                  "state": 1,
                  "letter": "u"
              }
          ]
      },
      "rowType": 0
  },
  {
      "guess": {
          "letters": [
              {
                  "letter": "d",
                  "state": 3
              },
              {
                  "letter": "i",
                  "state": 3
              },
              {
                  "letter": "e",
                  "state": 3
              },
              {
                  "letter": "n",
                  "state": 3
              },
              {
                  "letter": "u",
                  "state": 3
              }
          ]
      },
      "rowType": 0
  },
  {
      "guess": {
          "letters": [
              {
                  "letter": "j",
                  "state": 1
              },
              {
                  "letter": "a",
                  "state": 3
              },
              {
                  "letter": "u",
                  "state": 3
              },
              {
                  "letter": "n",
                  "state": 3
              },
              {
                  "letter": "s",
                  "state": 1
              }
          ]
      },
      "rowType": 0
  },
  {
      "guess": {
          "letters": [
              {
                  "state": 1,
                  "letter": "v"
              },
              {
                  "state": 1,
                  "letter": "ā"
              },
              {
                  "state": 3,
                  "letter": "r"
              },
              {
                  "state": 1,
                  "letter": "d"
              },
              {
                  "state": 1,
                  "letter": "s"
              }
          ]
      },
      "rowType": 0
  },
  {
      "guess": {
          "letters": [
              {
                  "state": 1,
                  "letter": "-"
              },
              {
                  "state": 1,
                  "letter": "4"
              },
              {
                  "state": 1,
                  "letter": "0"
              },
              {
                  "state": 1,
                  "letter": "4"
              },
              {
                  "state": 1,
                  "letter": "-"
              }
          ]
      },
      "rowType": 0
  }
];

export default function UnderConstruction() {
  const { styles } = useContext(ThemeContext);

  return (
    <View style={styles.components.page.container}>
      <Header hideIcons />
      <CustomText style={{ color: styles.colors.common.textPrimary, marginTop: 20, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize: 18,  }}>
        {`Vārdulis pašlaik nav pieejams.\nTiek veikti uzlabojumi.`}
      </CustomText>
      <WordGrid guesses={wordleData} letterSizeCustom={40}/>
    </View>
  );
}
