
export default {
  colors: {
    common: {
      primary: '#538d4e',
      primaryHover: '#406b3c',
      secondary: '#333333',
      secondaryHover: '#222222',
      warning: '#aa2222',
      warningHover: '#7d1a1a',
      textPrimary: 'white',
      textSecondary: '#d7dadc',
      background: '#121213',
      background2: '#aaaaaa',
      background3: '#333333',
      blurColor: 'rgba(0,0,0,0.5)',
      btnText: 'white'
    },
    gameplay: {
      textCorrect: 'white',
      textPartial: 'white',
      textWrong: 'lightgray',
      textUnused: 'white',
      textTileCorrect: 'white',
      textTilePartial: 'white',
      textTileWrong: 'white',
      textTileEmpty: 'white',
      bgTileCorrect: '#538d4e',
      bgTilePartial: '#b59f3b',
      bgTileWrong: '#28282a',
      bgBtnCorrect: '#538d4e',
      bgBtnPartial: '#b59f3b',
      bgBtnWrong: '#3a3a3c',
      bgBtnUnused: '#727475',
    }
  }
}