import { StyleSheet } from 'react-native';
import { BOX_SIZE, BOX_MARGIN } from './constants';

export const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    maxHeight: BOX_SIZE + (BOX_MARGIN * 2),
    maxWidth: BOX_SIZE + (BOX_MARGIN * 2),
    position: "relative",
    textAlign: "center",
  },
  box: {
    marginLeft: BOX_MARGIN,
    marginTop: BOX_MARGIN,
    maxWidth: BOX_SIZE,
    maxHeight: BOX_SIZE,
    borderColor: "#3a3a3c",
    borderWidth: 1,
    position: "absolute"
  },
  box_empty: {
    flexDirection: "column",
    margin: 2
  },
  box_correct: {
    flexDirection: "column",
    margin: 2,
    backgroundColor: "#538d4e"
  },
  box_partial: {
    flexDirection: "column",
    margin: 2,
    backgroundColor: "#b59f3b"
  },
  box_filled: {
    flexDirection: "column",
    margin: 2,
    backgroundColor: "#3a3a3c"
  },
  text: {
    color: '#d7dadc',
    // paddingTop: 10,
    fontSize: 30,
  },
  textContainer: {
    height: '100%',
    justifyContent:'center'
  }
});