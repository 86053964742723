import { useState, useContext, useEffect } from 'react';
import { Animated, View, Vibration } from 'react-native';
import { Letter } from '../Letter';
import { IRowProps } from './types';
import GuessAnimationContext from '@contexts/guessAnimationContext';

const ANIMATION_DURATION = 500;

export const ActiveRow = ({guess, letterSize}: IRowProps) => {
  const { isWrong, setIsWrong } = useContext(GuessAnimationContext);
  const [ hasAnimated, setHasAnimated ] = useState(true);

  const [animation,] = useState(new Animated.Value(0));

  useEffect(() => {
    if (isWrong) {

      Vibration.vibrate([10,100,80,100,50], true);
      // Vibration.stop();

      Animated.timing(animation, {
        toValue: hasAnimated ? 1 : 0,
        duration: ANIMATION_DURATION,
        useNativeDriver: false
      }).start( () => {
        setHasAnimated(current => !current);
        // Animated.timing(animation,{
        //   toValue:0,
        //   duration: ANIMATION_DURATION,
        //   useNativeDriver: false
        // }).start()
        setIsWrong(false);
      })
    }

  }, [isWrong])

  const boxInterpolation = animation.interpolate({
    inputRange: [0, 0.2, 0.4, 0.6, 0.8, 1],
    outputRange:[0, 10, -10, 10, -10, 0]
  })

  let animatedStyle = {
    transform: [{ translateX: boxInterpolation }],
  }

  return <>{
    guess?.letters?.map((letterData, index) => {
      return (
        <Animated.View key={index} style={[{ ...animatedStyle }]}>
          <Letter key={index} state={letterData.state} letter={letterData.letter} letterSize={letterSize} />
        </Animated.View>
      )
    })
  }</>
}