import { EStates, ILetter } from '../components/WordGrid/Letter/types';
import { IRow, ERowTypes } from '../components/WordGrid/Row/types';
import { IGuess } from '../components';
import { GUESS_COUNT, LETTER_COUNT, GUESS_LIST } from '../constants';

export const buildGuess = (correctWord: string, guess: string) => {
  if (!correctWord) {
    console.error('correctWord is not defined. Unable to build guess');
    return;
  }

  const letterArray: ILetter[] = [];
  let remainingLetters = correctWord;
  
  Array.from(guess).forEach((guessLetter: string, index: number) => {
    if (guessLetter == correctWord[index]) {
      const re = new RegExp(`${guessLetter}`, 'i');
      remainingLetters = remainingLetters.replace(re, '');

      letterArray.push({
        state: EStates.correct,
        letter: guessLetter
      });
    } else if (correctWord.indexOf(guessLetter) !== -1) {
      letterArray.push({
        // state: EStates.partial,
        letter: guessLetter
      });
    } else {
      letterArray.push({
        // state: EStates.filled,
        letter: guessLetter
      });
    }
  });

  // Loop over all the remaining letters, if it contains the key then make state 'partical', if not then make state filled
  letterArray.forEach((letter: ILetter) => {
    if (!letter.state && letter.letter && correctWord.includes(letter.letter) && remainingLetters.includes(letter.letter)) {
      const re = new RegExp(`${letter.letter}`, 'i');
      remainingLetters = remainingLetters.replace(re, '');
      letter.state = EStates.partial
    } 
  });

  letterArray.forEach((letter: ILetter) => {
    if (!letter.state) {
      letter.state = EStates.filled
    } 
  });

  const output: IGuess = {
    letters: letterArray
  }

  return output;
}

export const buildNewWord = (word: string, letterCount: number) => {
  if (Array.from(word).length > letterCount) return;
  const letterArray: ILetter[] = [];

  for (let i = 0; i < letterCount; i++) {
    let wordLetter = word[i];
    if (wordLetter) {
      letterArray.push({
        letter: wordLetter
      })
    } else {
      letterArray.push({
        letter: ' '
      })
    }
  }

  return {
    letters: letterArray
  }
}

export const buildEmptyRow = (letterCount: number) => {
  const letterArray: ILetter[] = [];
  for(let i = 0; i < letterCount; i++) {
    letterArray.push({
      state: EStates.empty
    })
  }
  return {
    letters: letterArray
  };
}

export const postProcessGuesses = (correctWord: string, guessArray: string[], currentWord: string, letterCount: number = 5) => {
  const output: IRow[] = [];

  if (correctWord) {
    for (let i = 0; i < Array.from(guessArray).length; i++) {
      const guess = buildGuess(correctWord, guessArray[i]);
      if (guess) {
        output.push({
          guess: guess,
          rowType: ERowTypes.filled
        });
      }
    }
  }

  if (output.length < GUESS_COUNT) {
    const word = buildNewWord(currentWord, letterCount);
    if (word) {
      output.push({
        guess: word,
        rowType: ERowTypes.active
      });
    }
  }
  for (let i = output.length; i < GUESS_COUNT; i++) {
    output.push({
      guess: buildEmptyRow(letterCount),
      rowType: ERowTypes.empty
    });
  }

  return output;
}
