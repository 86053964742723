import { useState, useEffect, useContext } from "react";
import { View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Header, WordGrid, CustomText } from '@components';
import { getDataFunctionsObject } from "@helpers/data";
import { TGameLog } from "@helpers/data/types";
import { postProcessGuesses } from "@helpers/words";
import { ThemeContext } from "@contexts/themeContext";

const useGame = (gameId: string) => {
  const [DF,] = useState(() => getDataFunctionsObject());
  const [game, setGame] = useState<TGameLog|undefined>();

  useEffect(() => {
    DF.getGame(gameId).then((game) => {
      setGame(game);
    });
  }, [gameId]);

  return {game, setGame};
}

export default function GameLog({ navigation, route }: any) {
  const { styles } = useContext(ThemeContext);
  const {game} = useGame(route.params.gameId);

  if (!game) {
    return <></>;
  }

  return (
    <View style={styles.components.page.container}>
      <Header navigation={navigation}/>
      <View style={{ marginTop: 'auto' }}>
        <WordGrid guesses={postProcessGuesses(game.gameState.correctWord, game.gameState.guessList, '', game.gameState.correctWord.length)} letterSizeCustom={40}/>
      </View>
      <CustomText style={{ color: styles.colors.common.textPrimary, marginTop: 20, textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', fontSize: 20,  }}>
        {game.date}
      </CustomText>
      <CustomText style={{ color: styles.colors.common.textPrimary, marginBottom: 'auto', textAlign: 'center', marginTop: 20, marginLeft: 'auto', marginRight: 'auto', fontSize: 18,  }}>
        Pareizais vārds bija "{game.gameState.correctWord.toUpperCase()}"
      </CustomText>
    </View>
  )
}