
import React, { useContext, useRef } from 'react';
import { Modal, Text, Pressable, View } from 'react-native';
import { CustomText, CustomTextRegular } from '../CustomText';
import {  } from '../CustomText';
import Constants from "expo-constants";
import * as Analytics from 'expo-firebase-analytics';
import { ThemeContext } from '@contexts/themeContext';
import CHANGES from "../../../changelog.json";
import { getDataFunctionsObject } from "../../helpers/data";

const CURRENT_CHANGES = CHANGES[CHANGES.length - 1];

export const ChangelogModal = ({ modalVisible, setModalVisible }: any) => {
  const DataFunctions = useRef(getDataFunctionsObject());
  let DF = DataFunctions.current;

  const { styles } = useContext(ThemeContext);
  const modalStyles = styles.components.modal;

  return (<Modal
    animationType="fade"
    transparent={true}
    visible={modalVisible}
    onRequestClose={() => {
      setModalVisible(!modalVisible);
      DF.setUpdateAccept(Constants?.manifest?.version || "");
    }}>
    <View style={modalStyles.centeredView}>
      <View style={[modalStyles.modalView, {shadowColor: 'black', shadowOpacity: 0.4, shadowRadius: 30}]}>
        <View style={modalStyles.headerView}>
          <CustomText style={{ color: styles.colors.common.textPrimary, marginBottom: 10, fontSize: 24 }}>{CURRENT_CHANGES.title}</CustomText>
          <CustomTextRegular style={{ color: styles.colors.common.textPrimary, marginBottom: 25 }}>Papildinājums {CURRENT_CHANGES.version}</CustomTextRegular>
        </View>
        <View>
          {CURRENT_CHANGES.changes.map((change: any, index: number) => {
            return (<CustomTextRegular key={index} style={{ color: styles.colors.common.textPrimary, textAlign: 'left', fontSize: 15, marginBottom: 8}}>{change}</CustomTextRegular>)
          })}
          <Pressable
            style={[modalStyles.button, modalStyles.buttonClose]}
            onPress={() => {
              setModalVisible(!modalVisible)
              DF.setUpdateAccept(Constants?.manifest?.version || "");
              try {
                Analytics.logEvent('UpdateAccept');
              } catch (e) {
                console.error("Firebase error");
              }
            }}>
            <Text style={modalStyles.textStyle}>SAPRATU</Text>
          </Pressable>
        </View>
      </View>
    </View>
  </Modal>)
}
