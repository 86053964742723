
export default (colors: any) => ({
  container: {
    flexDirection: "column",
    minHeight: "100%",
    backgroundColor: colors.common.background
  },
  menuContainer: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto', 
    width: "100%",
    marginTop: 40,
  },
  selectContainer: {
    flexDirection: "row",
    width: "100%",
  },
  select: {
    backgroundColor: colors.common.secondary,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 20,
    paddingLeft: 20,
    margin: 5,
    flex: 1,
    width: '100%',
    alignItems: 'center'
  },
  selectHighlight: {
    backgroundColor: colors.common.primary
  },
  selectText: {
    color: colors.common.btnText,
    fontSize: 14
  }
})

