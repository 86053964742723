import { useEffect, useState, useRef } from "react";
import { NotificationContext } from "../contexts/notificationContext";
import { getDataFunctionsObject } from "../helpers/data";
import { getAuth } from "firebase/auth";
import { getRemoteConfig, getValue, getString, fetchAndActivate } from "firebase/remote-config";
import FirebaseApi from '../helpers/Firebase';
import Constants from 'expo-constants';

const Firebase = new FirebaseApi();
const FETCH_TIMEOUT = 2 * 60 * 1000;

export const useConfig = () => {
  const intervalRef = useRef<any>(null);
  const [config, setConfig] = useState(Firebase.AvailabilityConfig);
  
  const fetchAndActivateConfig = () => {
    if (Constants?.expoConfig?.extra?.env === "development") return;

    fetchAndActivate(Firebase.remoteConfig)
    .then((res) => {
      // console.log(res);
      Firebase.AvailabilityConfig = JSON.parse(getString(Firebase.remoteConfig, 'AvailabilityConfig'));
      setConfig(Firebase.AvailabilityConfig);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    fetchAndActivateConfig();
    intervalRef.current = setInterval(() => {
      fetchAndActivateConfig();
    }, FETCH_TIMEOUT);

    return () => clearInterval(intervalRef.current);
  }, [])

  return {config};
}
