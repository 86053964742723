import React, { useRef, useEffect, useState, useContext } from 'react';
import { Animated, Text, View } from 'react-native';
import { CustomText } from '../../CustomText';
import { styles } from './styles';
import { ILetter, EStates } from './types';
import { ANIMATION_DURATION, BOX_MARGIN } from './constants';
// import GuessAnimationContext from '@contexts/guessAnimationContext';
import { ThemeContext } from '@contexts/themeContext';

export const Letter = ({ state = EStates.empty, letter, letterSize = 32 }: ILetter) => {
  const { styles } = useContext(ThemeContext);
  const [animation,] = useState(new Animated.Value(0));
  const letterStyles = styles.components.grid.letter;

  useEffect(() => {
    if (letter && letter !== ' ') {
      Animated.timing(animation, {
        toValue:1,
        duration: ANIMATION_DURATION,
        useNativeDriver: false
      }).start( () => {
        Animated.timing(animation,{
          toValue:0,
          duration: ANIMATION_DURATION,
          useNativeDriver: false
        }).start()
      })
    }
  }, [letter])

  const boxInterpolation = animation.interpolate({
    inputRange: [0, 1],
    outputRange:[1, 1.2]
  });

  let animatedStyle = {
    transform: [{ scale: boxInterpolation }],
  }
  
  return (
    <View style={[letterStyles.container, { width: letterSize + (BOX_MARGIN * 2), height: letterSize + (BOX_MARGIN * 2)}]}>
      <Animated.View style={[letterStyles.box, { ...animatedStyle}, { width: letterSize, height: letterSize, borderWidth: letter !== ' ' && letter ? 2 : 1 }]}>
        <CustomText style={[letterStyles.text_empty]}>{letter?.toUpperCase()}</CustomText>
      </Animated.View>
    </View>
  );
}
